.slot-selector .each-time-slot {
    border: 1px solid #185BDB;
    color: #185BDB;
    border-radius: 5px;
    padding: 10px;
    margin: 0 10px 10px 0;
    display: inline-block;
    cursor: pointer;
    width: 152px;
    text-align: center;
}

.slot-selector .each-time-slot.active {
    background-color: #185BDB;
    color: #fff;
}

.slot-selector .section-header,
.slot-selector .all-label {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #185BDB !important;
}

.slot-selector .section-header {
    margin-bottom: 15px;
    opacity: 0.7;
}

.slot-selector .section-header img {
    margin-right: 10px;
    width: 25px;
}

.slot-selector .section-header.selected {
    opacity: 1;
}

.slot-selector .appointment-type .badge-pill {
    font-size: 12px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 1px solid #185BDB;
    color: #185BDB;
    border-radius: 5px;
    cursor: pointer;
    width: 48%;
    padding: 12px 0;
}

.slot-selector .appointment-type .badge-primary {
    background-color: #185BDB;
    color: #fff;
}

@media screen and (max-width: 576px) {
    .slot-selector .appointment-type .badge-pill {
        width: 98%;
    }
    .slot-selector .each-time-slot {
        padding: 5px;
        margin: 0 5px 7px 0;
        font-size: 11px;
        width: 125px;
    }
}

@media screen and (max-width: 400px) {
    .slot-selector .each-time-slot {
        width: 100%;
        margin: 0 0 7px 0;
    }
}