.home-container .part-1-left {
    padding: 40px 0;
}

.home-container .home-header {
    font-size: 32px;
    color: #1B3467;
    font-weight: 600;
    line-height: 1.2;
}

.home-container .home-info {
    font-size: 16px;
    color: #566986;
    font-family: 'Poppins Regular';
    font-weight: 500;
}

.home-container .home-info-1 {
    font-size: 14px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.home-container .part-2-1 {
    padding: 50px 0;
    background-color: #ECF2FF;
}

.home-container .part-2-2 {
    padding: 50px 0;
}

.home-container .part-2-left-wrapper {
    /* width: 60%; */
    max-width: 450px;
    width: 100%;
}

.home-container .part-2-header {
    font-size: 24px;
    color: #1B3467;
    font-weight: 600;
}

.home-container .part-2-info {
    font-size: 16px;
    color: #566986;
    font-family: 'Poppins Regular';
    font-weight: 500;
}

.home-container .part-4 .part-2-info {
    color: #A7B5CA;
}

.home-container .part-2 .part-2-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 450px;
}

.home-container .part-3 {
    background-color: #EFF5FF;
    border-radius: 20px;
    margin: 0 auto 50px;
    max-width: 900px;
    width: 100%;
    padding: 50px;
}

.home-container .part-3-header {
    color: #1B3467;
    font-weight: 600;
    font-size: 16px;
}

.home-container .part-3-info {
    font-size: 14px;
    color: #566986;
    font-family: 'Poppins Regular';
    font-weight: 400;
}

.home-container .part-4 {
    padding: 50px 0;
    background-color: #12254E;
    margin: 50px 0;
}

.home-container .part-4-open-option {
    background: #0F1E40;
    border: 1.5px solid rgba(255, 255, 255, 0.69);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.home-container .part-4-closed-option {
    background: #566986;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.home-container .part-4-open-option .right-wrapper,
.home-container .part-4-closed-option .right-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    margin-left: 10px;
}

.home-container .part-4-open-option img,
.home-container .part-4-closed-option img {
    width: 15px;
    vertical-align: top;
    margin-top: 5px;
}

.home-container .option-header {
    font-size: 16px;
    color: #fff;
    font-family: 'Poppins Regular';
    font-weight: 500;
}

.home-container .option-info {
    font-size: 14px;
    color: #B2C1D7;
    font-family: 'Poppins Regular';
    font-weight: 400;
}

.home-container .part-5 .wrapper {
    background-color: #EFF5FF;
    padding: 40px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.home-container .part-5 .wrapper img {
    max-width: 100px;
    width: 100%;
}

.home-container .part-5-hrader {
    font-size: 14px;
    margin-top: 10px;
}

.home-container .part-6 {
    background-color: rgba(236, 242, 255, 0.7)
}

.home-container .part-6 .registered-office {
    font-weight: 600;
}

.home-container .part-6 .email {
    color: #3470dd;
    font-weight: 600;
}

.home-container .part-6 .part-2-info {
    font-size: 14px;
    color: #566986;
}

.home-container .common-width {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.home-container .common-top-bottom-padding {
    padding: 50px 0;
}

.home-container .part-1-image {
    width: 400px;
}

.home-container .part-3-image-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 6px 8px 31px rgba(24, 91, 219, 0.15);
    cursor: pointer;
}

.home-container .part-3-image {
    width: 25px;
    vertical-align: -webkit-baseline-middle;
}

.home-container .part-4-image {
    width: 300px;
}

.home-container .below-md-part-2-image-wrapper {
    display: none;
}

.home-container .join-as-professional-heading {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.home-container .join-as-professional-links-wrapper span {
    color: #B2C1D7;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.home-container .join-as-professional-links-wrapper img {
    width: 13px;
    margin-right: 7px;
    vertical-align: sub;
    margin-left: 12px;
}

.home-container .part-1-wrapper .link-wrapper {
    margin-top: 10px;
}

.home-container .part-1-wrapper .link-wrapper img {
    margin-right: 10px;
    /* vertical-align: super; */
    width: 140px;
    cursor: pointer;
}

.home-container .part-7 {
    /* padding: 50px 0; */
    text-align: center;
    margin: 50px auto 30px;
    background-color: rgba(236, 242, 255, 0.7);
}

.home-container .part-7 .part-7-border {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.home-container .part-7 .social-icon-wrapper {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
}

.home-container .part-7 .social-icon-wrapper img {
    width: 100%;
}

.home-container .part-7 .icons-wrapper {
    width: 200px;
    float: right;
    margin-top: 27px;
}

.home-container .part-7 .iso-image-wrapper {
    float: left;
    width: 175px;
}

.home-container .part-7 .iso-image {
    width: 100%;
}

.home-container .part-6-disclaimer {
    padding-right: 75px;
}

.other-header-wrapper {
    display: table;
    height: 200px;
    width: 100%;
    background-image: -webkit-linear-gradient(180deg,#5e2ced 0,#a485fd 100%);
    color: #fff;
    background-image: -moz-linear-gradient(180deg,#5e2ced 0,#a485fd 100%);
    background-image: -ms-linear-gradient(180deg,#5e2ced 0%,#a485fd 100%);
    overflow: hidden;
    margin-bottom: 40px;
}

.other-header-wrapper .other-header {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.other-page-wrapper {
    padding: 50px;
}

.home-container .whole-categories-wrapper {
    position: relative;
}

.home-container .customers-wrapper,
.home-container .vendors-wrapper {
    padding: 15px 0;
    border-radius: 8px;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}

.home-container .customers-wrapper::-webkit-scrollbar,
.home-container .vendors-wrapper::-webkit-scrollbar {
    display: none;
}

.home-container .full-event-list-wrapper {
    width: auto;
    display: inline-block;
}

.home-container .event-custom-card {
    width: 280px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 15px;
    height: 280px;
    background-color: #fff;
    /* cursor: pointer; */
    /* border: 1.5px solid #D5E0F0; */
    /* border-left: 1.5px solid #D5E0F0; */
    /* border-bottom: 1.5px solid #D5E0F0; */
    /* border-bottom-left-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -webkit-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -moz-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
}

/* .home-container .event-background-image-category {
    width: 100%;
    height: 70px;
} */

.home-container .prev-button-wrapper {
    height: 45px;
    background-color: #09193C;
    color: #ffffff;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #253D72;
    font-size: 14px;
}

.home-container .next-button-wrapper {
    height: 45px;
    width: 45px;
    color: #ffffff;
    background-color: #09193C;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #253D72;
    font-size: 14px;
}

.home-container .next-button-wrapper:disabled,
.home-container .prev-button-wrapper:disabled {
    background-color: #09193B;
    cursor: default;
    opacity: 0.5;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.home-container .next-button-wrapper:hover,
.home-container .prev-button-wrapper:hover {
    border: 1px solid #253D72;
    filter: none;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
}

@media only screen and (max-width: 870px) {
    .home-container .part-1-image {
        width: 300px;
        margin-top: 50px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1060px) {
    .home-container .common-width {
        max-width: 750px;
    }

    .home-container .part-3 {
        max-width: 750px;
    }

    .home-container .part-2-left-wrapper {
        max-width: 330px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
    .home-container .common-width {
        max-width: 730px;
    }

    .home-container .part-3 {
        max-width: 730px;
    }

    .home-container .part-2-left-wrapper {
        max-width: 330px;
    }
}

@media only screen and (min-width: 769px) {

    .home-container .part-1-image-wrapper,
    .home-container .part-4-image-wrapper {
        text-align: right;
    }
}

@media only screen and (max-width: 768px) {
    .home-container .home-info {
        padding-left: 25px;
        padding-right: 25px;
    }
    .home-container .part-1-image {
        margin-top: 0;
    }

    .home-container .part-1-image-wrapper,
    .home-container .part-1-wrapper,
    .home-container .part-2-wrapper,
    .home-container .part-4-image-wrapper {
        text-align: center;
    }

    .home-container .part-2-image {
        display: none;
    }

    .home-container .below-md-part-2-image-wrapper {
        display: block;
        text-align: center;
    }

    .home-container .below-md-part-2-image {
        max-width: 400px;
        width: 100%;
    }

    .home-container .part-2-1,
    .home-container .part-2-2 {
        padding: 25px 0;
    }

    .home-container .part-4 {
        margin-bottom: 0;
    }

    .home-container .part-2-info-width {
        padding-left: 25px;
        padding-right: 25px;
    }

    .home-container .part-4 .full-wrapper {
        margin-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .home-container .common-top-bottom-padding {
        padding: 25px 0;
    }

    .home-container .part-1-left {
        padding: 25px 0;
    }

    .home-container .part-7 {
        margin-top: 25px;
        padding: 25px 0;
    }

    .home-container .part-2-left-wrapper {
        max-width: 100%;
    }

    .home-container .part-3 {
        padding: 25px;
    }

    .home-container .part-6,
    .home-container .part-7,
    .home-container .home-info-1 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .home-container .part-7 {
        margin: 50px auto 0;
    }

    .home-container .part-7 .icons-wrapper,
    .home-container .part-7 .iso-image-wrapper {
        float: none;
        margin: 0 auto;
    }

    .home-container .part-7 .icons-wrapper {
        margin-top: 10px;
    }
    
}

@media only screen and (max-width: 577px) {
    .home-container .part-5 .common-width {
        max-width: 280px;
    }
    .home-container .part-6-disclaimer {
        padding-right: 15px;
    }
}