.dashboard .background-image-category {
    width: 60px;
    height: 60px;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: contain;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid #CED9EB;
}
.dashboard .sidenav .current-loc {
    color: #185BDB;
    margin-top: 5px;
    cursor: pointer;
}
.dashboard .event-background-image-category {
    width: 100%;
    height: 70px;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 100%;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dashboard .category-name {
    text-align: center;
    font-size: 12px;
    color: #566986;
    font-weight: 600;
    padding-top: 7px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
    font-family: 'Poppins Regular';
}

.dashboard .event-name {
    -webkit-line-clamp: 1;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    height: 30px;
    margin-top: 77px;
    background-color: #0A1935;
    opacity: 0.9;
    line-height: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    padding-top: 5px;
    text-align: left;
}

.dashboard .workshop-name {
    -webkit-line-clamp: 1;
    padding-right: 10px;
    padding-left: 0;
    width: 210px;
    line-height: 20px;
    color: #374561;
    padding-top: 5px;
    text-align: left;
}

.dashboard .see-all-events {
    font-size: 14px;
    color: #185CD9;
    font-family: 'Poppins Medium';
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.dashboard .categories-wrapper,
.dashboard .events-wrapper,
.dashboard .workshops-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}

.dashboard .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 20px 7px;
    margin-right: 15px;
    height: 130px;
    background-color: #fff;
    cursor: pointer;
}

.dashboard .event-custom-card {
    width: 210px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 15px;
    height: 106px;
    background-color: #fff;
    cursor: pointer;
    /* border: 1.5px solid #D5E0F0; */
    /* border-left: 1.5px solid #D5E0F0; */
    /* border-bottom: 1.5px solid #D5E0F0; */
    /* border-bottom-left-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -webkit-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -moz-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
}

.dashboard .next-button-wrapper {
    height: 45px;
    width: 45px;
    color: #185BDB;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.dashboard .prev-button-wrapper {
    height: 45px;
    background-color: #ffffff;
    color: #185BDB;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.dashboard .slot-selector {
    background-color: #fff;
    margin: 15px 0;
    padding: 20px;
    border-radius: 20px;
}

.dashboard .booking-gallery .prev-button-wrapper,
.dashboard .booking-gallery .next-button-wrapper {
    height: 35px;
    width: 35px;
}

.dashboard .next-button-wrapper:disabled,
.dashboard .prev-button-wrapper:disabled {
    background-color: #EDF2FF;
    cursor: default;
    opacity: 0.5;
}

.dashboard .next-button-wrapper:hover,
.dashboard .prev-button-wrapper:hover {
    border: 1px solid #E6ECF8;
    filter: none;
}

.dashboard .next-button-wrapper button,
.dashboard .prev-button-wrapper button {
    margin: 35px 0;
}

.dashboard .categories-heading {
    padding: 15px;
    position: relative;
}

.dashboard .profile-user-details-side-by-side {
    display: inline-block;
    padding: 0 10px;
}

.dashboard .categories-heading h4 {
    font-family: 'Poppins Semi Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B3467;
}

.dashboard .categories-heading .category-arrows {
    margin-right: 0;
    margin-left: 0;
}

.dashboard .gallery-wrapper .category-arrows {
    margin-right: 0;
    margin-left: 0;
}

.dashboard .gallery-wrapper h4 {
    color: #1B3467;
    font-size: 18px;
    font-family: 'Poppins Medium';
    font-weight: 600;
}

.dashboard .services-list-wrapper h4 {
    color: #1B3467;
    font-size: 18px;
    font-family: 'Poppins Medium';
    font-weight: 600;
    padding: 20px 0;
    margin-bottom: 0;
}

.dashboard .profile-description-header {
    color: #374561;
    font-size: 15px;
    font-family: 'Poppins Medium';
}

.dashboard .gallery-wrapper {
    padding: 20px 0;
    border-top: 2px solid #D4DEEF;
    border-bottom: 2px solid #D4DEEF;
    margin-top: 20px;
}

.dashboard .profile-description-text {
    color: #566986;
    font-size: 13px;
    font-family: 'Poppins Regular';
}

.dashboard .professional-count {
    margin-left: 10px;
    color: #185CD9;
    font-size: 13px;
    font-family: 'Poppins Medium';
}

.dashboard .categories-heading .home-page-header {
    color: #1B3467;
    font-family: 'Poppins Semi Bold';
    font-size: 20px;
    width: calc(100% - 85px);
}

.dashboard .professional-wrapper {
    padding: 0;
}

.dashboard .booking-details-change-button .give-feedback-button {
    width: 180px !important;
}

.dashboard .categories-wrapper::-webkit-scrollbar,
.dashboard .events-wrapper::-webkit-scrollbar,
.dashboard .workshops-wrapper::-webkit-scrollbar {
    display: none;
}

.dashboard .professional-wrapper .row {
    padding: 21px 15px;
    background: #ECF2FF;
    border-radius: 20px;
    cursor: pointer;
}

.dashboard .professional-name {
    font-weight: 600;
    color: #1B3467;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}

.dashboard .professional-designation {
    font-size: 13px;
    color: #185CD9;
    font-family: 'Poppins Regular';
}

.dashboard .professional-location {
    font-size: 12px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.dashboard .professional-exp {
    font-size: 12px;
    margin-top: 5px;
    color: #566986;
    font-family: 'Poppins Medium';
}

.dashboard .professional-exp span {
    float: right;
}

.dashboard .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.dashboard .background-image-professional,
.dashboard .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
}

.dashboard .whole-categories-wrapper {
    position: relative;
    padding: 0 15px;
}

.dashboard .categories-heading .navbar-nav {
    position: absolute;
    top: 6px;
    right: 0;
    width: 50px;
    cursor: pointer;
}

.dashboard .background-image-premium {
    width: 40px;
    height: 40px;
    margin: 0 auto;
}

.dashboard .premium-listing-wrapper {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
    height: 300px;
    overflow-y: auto;
    background-color: #fff;
    margin-top: 12px;
}

.dashboard .profile-image,
.dashboard .review-image {
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100px;
    width: 100%;
    border-radius: 50%;
    margin: 0;
    border: 4px solid #ffffff;
    box-shadow: 6px 14px 31px #dae4f4;
    -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
    -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.dashboard .profile-name {
    color: #374561;
    font-size: 16px;
    font-family: 'Poppins Medium';
    word-break: break-all;
    font-weight: 600;
}

.dashboard .profile-designation {
    color: #566986;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
    word-break: break-all;
    font-family: 'Poppins Regular';
    font-weight: 500;
}

.dashboard .profile-address-title {
    color: #374561;
    font-size: 14px;
    font-family: 'Poppins Medium';
}

.dashboard .profile-exp {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.dashboard .profile-rating {
    font-size: 13px;
    color: #566986;
}

.dashboard .profile-rating i {
    color: #E12936;
}

.dashboard .profile-rating-wrapper {
    margin-top: 5px;
    margin: 5px 0 10px 0;
}

.dashboard .white-bg {
    background-color: #fff !important;
}

.dashboard .profile-rating-wrapper .rate-star {
    margin: 0 3px;
    font-size: 16px;
    cursor: pointer;
    color: #E12936;
}

.dashboard .reschedule-button {
    width: 120px !important;
}

.dashboard .profile-rating-wrapper span {
    color: #185BDB;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: sub;
}

.dashboard .profile-details-wrapper {
    padding: 20px;
    border-radius: 20px;
    background-color: #EFF5FF;
}

.dashboard .user-profile-details-wrapper {
    background-color: #ffffff;
}

.dashboard .profile-name-wrapper {
    padding-left: 15px;
    position: relative;
}

.dashboard .big-book-now-button {
    width: 100%;
    margin-top: 10px;
    padding: 4px;
    font-size: 12px;
    background-color: #185BDB;
}

.dashboard .follow-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    padding: 4px 12px;
}

.dashboard .profile-menu-options {
    margin-top: 15px;
    margin-bottom: 15px;
}

.dashboard .about-label {
    font-weight: 600;
}

.dashboard .about-text {
    font-weight: 600;
    color: #185BDB;
}

.dashboard .description-image {
    height: 100px;
    margin: 5px;
    cursor: pointer;
    border-radius: 8px;
}

.dashboard .service-cost {
    color: #185BDB;
    margin-top: 5px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
}

.dashboard .small-book-now-button,
.dashboard .small-book-now-button:hover {
    padding: 4px;
    font-size: 13px;
    background-color: #FFFFFF !important;
    border: 1px solid #E6ECF8;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border-radius: 50px;
    color: #185BDB;
    width: 150px;
    margin-left: 10px;
    filter: none;
}

.dashboard .small-book-now-button:focus,
.dashboard .small-book-now-button:active {
    color: #185BDB !important;
}

.dashboard .service-wrapper .row {
    cursor: default;
    border-radius: 0;
    padding: 20px 20px 0;
}

.dashboard .service-name {
    color: #566986;
    font-family: 'Poppins Regular';
    font-weight: 400;
    font-size: 14px;
}

.dashboard .service-list-name {
    color: #374561;
    font-family: 'Poppins Medium';
    font-weight: 600;
    font-size: 16px;
}

.dashboard .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
    background-color: #fff;
    border-radius: 8px;
}

.dashboard .booking-details-header {
    color: #185BDB;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 16px;
    font-family: 'Poppins Medium';
}

.dashboard .cancel-info {
    color: #374561;
    font-weight: 400;
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Poppins Regular';
}


.dashboard .booking-details-name {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
}

.dashboard .booking-details-description {
    font-size: 14px;
    padding-bottom: 5px;
    color: #aaa;
}

.dashboard .booking-details-change-button {
    padding: 10px;
    text-align: center;
}

.dashboard .booking-details-change-button button {
    width: 50%;
    padding: 4px;
}

.dashboard .booking-details-slot {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
    color: #185BDB;
}

.box-shadow-wrapper {
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
    padding: 10px;
    border-radius: 8px;
}

.dashboard .subscribe-default-button {
    border-color: #293ccc !important;
    color: #293ccc !important;
}

.dashboard .subscribe-default-button:hover {
    color: #fff !important;
}

.dashboard .fee-label {
    font-weight: 600;
    font-size: 14px;
}

.dashboard .fee-text {
    font-weight: 600;
    color: #374561;
    font-family: 'Poppins Regular';
    display: inline-block;
    font-size: 13px;
    padding: 0 10px;
    word-break: break-all;
}

.dashboard .bor-right {
    border-right: 2px solid #6B7C95;
    ;
}

.dashboard .service-details-wrapper {
    padding: 20px 0;
    border-top: 2px solid #D4DEEF;
    border-bottom: 2px solid #D4DEEF;
    margin-top: 20px;
}

.dashboard .profile-amount {
    font-size: 14px;
    color: #185BDB;
    font-family: 'Poppins Medium';
    margin-top: 5px;
}

.dashboard .fee-total-label {
    color: #000;
}

.dashboard .fee-total-wrapper {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

.dashboard .appointment-number-details {
    font-size: 20px;
    letter-spacing: 10px;
    color: #185BDB;
}

.dashboard .slot-wrapper .MuiTextField-root {
    width: 100%;
    margin-top: 9px;
}

.dashboard .slot-wrapper .MuiInput-input {
    background-color: #ffffff;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
    height: 20px;
    padding: 6px 10px;
}

.dashboard .notes-wrapper {
    border-top: 2px solid #D4DEEF;
    padding-top: 20px;
}

.dashboard .notes-wrapper .label {
    color: #1B3467;
    font-size: 14px;
    font-family: 'Poppins Medium';
    margin-bottom: 5px;
}

.dashboard .notes-wrapper .notes {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.dashboard .slot-wrapper.white-bg .MuiInput-input {
    background-color: #EFF5FF;
}

.dashboard .slot-wrapper .form-label {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
    font-weight: 400;
}

.dashboard .slot-wrapper h6 {
    color: #374561;
    font-family: 'Poppins Medium';
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
}

.dashboard .slot-wrapper .MuiInput-underline:before {
    border-bottom: 0;
}

.dashboard .appointment-details-button-wrapper .btn-primary,
.dashboard .appointment-details-button-wrapper .btn-outline-primary,
.dashboard .booking-details-change-button .btn-primary {
    margin-left: 5px;
    font-size: 14px;
    background: #185BDB;
    border: 1px solid #185BDB;
    border-radius: 50px;
    padding: 10px 15px;
    width: 100px;
    color: #ffffff;
    font-family: 'Poppins Regular';
}

.dashboard .pay-now-button .btn-primary {
    max-width: 200px;
    width: 100%;
}

.dashboard .services-list-wrapper .back-button-wrapper button {
    font-size: 13px;
}

.dashboard .slot-wrapper {
    background-color: #ECF2FF;
    padding: 30px 20px;
    border-radius: 10px;
    margin-top: 15px !important;
}

.dashboard .appointment-details-button-wrapper .btn-outline-primary,
.dashboard .appointment-details-button-wrapper .btn-outline-primary:hover {
    background: #ffffff;
    border: 1px solid #E6ECF8;
    color: #7A8EB0;
    filter: none;
}

.dashboard .booking-profile-image {
    width: 80px;
    height: 80px;
    display: inline-block;
}

.dashboard .booking-profile-image .background-image-professional {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    border: 4px solid #ffffff;
    box-shadow: 6px 14px 31px #dae4f4;
    -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
    -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.dashboard .booking-details-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 10px;
}

.dashboard .profile-icon-wrapper i,
.dashboard .profile-icon-wrapper img {
    margin-right: 10px;
    cursor: pointer;
}

.dashboard .profile-icon-wrapper {
    margin-top: 5px;
}

.dashboard .profile-icon-wrapper i {
    color: #185BDB;
    font-size: 14px;
    vertical-align: middle;
}

.dashboard .pt-20 {
    padding-top: 20px;
}

.dashboard .pl-20 {
    padding-left: 20px;
}

.dashboard .pb-20 {
    padding-bottom: 20px !important;
}

.dashboard .profile-icon-wrapper img {
    width: 16px;
}


.ask-question-modal .login-logo-wrapper {
    text-align: center;
}

.ask-question-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.ask-question-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.ask-question-modal .login-button {
    padding: 0;
    text-align: center;
}

.ask-question-modal .login-button button {
    /* width: 100%; */
    margin-bottom: 5px;
    font-size: 14px;
}

.ask-question-modal .login-button .btn-link {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
}

.ask-question-modal .login-button .btn-link:hover {
    background-color: transparent;
    color: #185BDB;
}

.ask-question-modal .login-phone-input {
    padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
    color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.ask-question-modal .modal-body {
    padding: 10px 25px 25px 25px;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .question-info {
    margin-bottom: 15px;
    font-size: 14px;
    color: #566986;
    font-family: 'Poppins Medium';
}

.ask-question-modal .question-input label {
    color: #374561;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}

.ask-question-modal .question-info i {
    margin-right: 10px;
}

.ask-question-modal .modal-title {
    color: #1B3467 !important;
    font-size: 26px !important;
    font-family: 'Poppins Semi Bold';
    font-weight: 600 !important;
}

.review-modal .profile-rating-wrapper img {
    width: 25px;
}

.review-modal .review-label {
    color: #000;
    font-size: 14px;
    font-family: 'Open Sans Regular', sans-serif;
}

.review-modal .review-profile-details {
    border-bottom: 1px solid #ccc;
    padding: 5px 0 15px;
    margin: 0 0 15px 0;
}

.review-modal .background-image-premium {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    border-radius: 8px;
    width: 100%;
}

.review-modal .profile-photo {
    width: 60px;
    display: inline-block;
}

.review-modal .profile-other-details {
    width: calc(100% - 110px);
    display: inline-block;
    padding-left: 15px;
}

.my-profile .profile-photo-wrapper {
    width: 120px;
    display: inline-block;
}

.my-profile .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.my-profile .profile-other-details {
    width: calc(100% - 130px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .profile-image-wrapper {
    width: 100px;
    display: inline-block;
}

.dashboard .review-image-wrapper {
    width: 50px;
    display: inline-block;
}

.dashboard .review-section {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
}

.dashboard .booking-status-wrapper {
    padding-top: 40px;
}

.dashboard .review-other-details {
    width: calc(100% - 50px);
    display: inline-block;
    padding-left: 15px;
    text-align: left;
    vertical-align: top;
}

.dashboard .review-image {
    height: 50px;
}

.dashboard .profile-other-details {
    width: calc(100% - 100px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .profile-service-desc {
    font-size: 12px;
    color: #566986;
    font-family: 'Poppins Regular';
    margin: 5px 0;
}

.dashboard .profile-date-time {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.dashboard .profile-date-time .label {
    color: #1B3467;
    font-size: 14px;
    font-family: 'Poppins Medium';
    margin-bottom: 5px;
}

.dashboard .pr-20 {
    padding-right: 20px;
}

.dashboard .left-booking-item {
    border-right: 2px solid #D4DEEF;
    margin-bottom: 10px;
}

.dashboard .about-image-wrapper {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
}

.dashboard .tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
    z-index: 9;
}

.dashboard .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.dashboard .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.dashboard .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.dashboard .selected-card {
    box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -webkit-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -moz-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    border-radius: 15px;
}

.dashboard .anchor-link {
  font-size: 14px;
  color: #185bdb;
  font-family: "Poppins Medium";
  margin-top: 5px;
}

.dashboard .selected-card .background-image-category {
    border: 3px solid #185CD9;
}

.dashboard .selected-card-name {
    color: #185CD9;
    font-family: 'Poppins Medium';
}

.dashboard .service-wrapper .bor-rad-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.dashboard .service-wrapper .bor-rad-bot {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.dashboard .services-sessions-button {
    border-radius: 50px;
    border: 1px solid #F4F8FF;
    color: #566986;
    padding: 10px 20px;
    background-color: #F4F8FF !important;
    font-family: 'Poppins Regular';
    margin-right: 10px;
}

.dashboard .services-sessions-button.active {
    border: 1px solid #E6ECF8;
    color: #185BDB !important;
    background-color: #ffffff !important;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border: 1px solid #E6ECF8;
    font-family: 'Poppins Medium';
}

.dashboard .services-sessions-button:hover {
    filter: none;
    color: inherit;
}

.dashboard .seat-count {
    color: #185BDB;
}

.dashboard .hide-less-575 {
    display: block;
}

.dashboard .show-less-575 {
    display: none;
}

.dashboard .hide-less-768 {
    display: block;
}

.dashboard .show-less-768 {
    display: none;
}
.dashboard .event-list-date-time-wrapper {
    width: auto;
    margin-top: 5px;
}

.dashboard .full-event-list-wrapper {
    width: auto;
    display: inline-block;
}

.dashboard .event-list-date,
.dashboard .event-list-time {
    display: inline-block;
    font-size: 11px;
    font-family: 'Poppins Regular';
}

.dashboard .event-list-date {
    margin-right: 5px;
}

.dashboard .test-wrapper {
    position: absolute;
    right: 0;
    top: 5px;
}

.dashboard .test-wrapper .selected-tests {
    font-size: 13px;
    color: #000;
    font-family: 'Poppins Regular';
    margin-right: 10px;
}

.dashboard .test-wrapper .see-all-tests-link {
    font-size: 13px;
    color: #185BDB;
    font-family: 'Poppins Regular';
    cursor: pointer;
    text-decoration: underline;
}

.dashboard .test-button {
    width: 70px;
    padding: 5px !important;
    font-size: 11px;
}

.dashboard .test-border-768 {
    border-left: 2px solid #D4DEEF;
}

.dashboard .booking-profile-image .promo-border-professional,
.dashboard .profile-image-wrapper .promo-border-professional {
    border: 4px solid #FFDD2C;
}

.profile-workshop-list .workshop-mobile-view-only {
    display: none;
}

.profile-workshop-list .workshop-desktop-view-only {
    display: block;
}


.profile-workshop-list .workshop-image {
    width: 200px;
    height: 180px;
}

.profile-workshop-list .workshop-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.profile-workshop-list .workshop-details-wrapper {
    width: calc(100% - 200px);
}

.profile-workshop-list .amount-image-wrapper {
    position: inherit;
    top: 0;
    left: 0;
    height: 25px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 13px;
    font-family: "Poppins Medium";
    text-align: left;
    padding-top: 3px;
    padding-left: 10px;
}
.profile-workshop-list .professional-date-time {
  color: #566986;
  font-size: 14px;
  font-family: "Poppins Regular";
}
.profile-workshop-list .professional-date-time .label {
    color: #1b3467;
    font-size: 14px;
    font-family: "Poppins Medium";
    margin-bottom: 5px;
}

.profile-workshop-list .details-button {
    box-shadow: 3px 7px 12px rgb(24 91 219 / 8%);
    -webkit-box-shadow: 3px 7px 12px rgb(24 91 219 / 8%);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border-radius: 50px;
    border: 1px solid #185bdb;
    padding: 10px 20px;
}

.dashboard .search-input-wrapper {
    width: 450px;
    margin: 0 auto;
    height: 40px;
}

.dashboard .search-input-wrapper input {
    height: 40px;
}

.dashboard .search-input-wrapper .input-group-prepend button,
.dashboard .search-input-wrapper .bor-radius {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.dashboard .search-input-wrapper .input-group-append button {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.dashboard .desktop-view .badge-pill {
    font-size: 12px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 1px solid #185BDB;
    color: #185BDB;
    border-radius: 5px;
}

.dashboard .desktop-view.bor-left-1 {
    border-left: 1px solid #ccc;
}

.dashboard .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #4c4c4c;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 20px 0;
  font-weight: 500;
  -webkit-box-shadow: 10px 0px 18px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 0px 18px -3px rgba(0,0,0,0.75);
    box-shadow: 10px 0px 18px -3px rgba(0,0,0,0.75);
}
.dashboard .sidenav label {
    font-size: 14px;
    color: #1b3467;
}
.dashboard .sidenav .section-header {
    font-weight: 600;
}

.dashboard .sidenav .radius-range {
    position: relative;
}

.dashboard .sidenav .common-wrapper {
    padding: 7px 0;
}

.dashboard .sidenav .common-background-wrapper {
    background-color: #ECF2FF;
    padding: 15px;
    border-radius: 8px;
}

.dashboard .filter-applied {
    color: #185CD9;
}

.dashboard .filter-icon-wrapper .dot {
    background-color: #E12936;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    right: 12px;
    top: 12px;
}

.dashboard #overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
  cursor: default; /* Add a pointer on hover */
}

.dashboard .sidenav .day-wrapper,
.dashboard .sidenav .appointment-type-wrapper {
    border: 1px solid #bcdcfa;
    border-radius: 5px;
    padding: 10px;
}

.dashboard .sidenav .checkbox-wrapper .form-check-inline {
    width: 25%;
}

.dashboard .sidenav .apply-wrapper {
    padding: 25px 0;
}

.dashboard .sidenav .apply-wrapper button {
    width: 200px;
    background-color: #185CD9;
}

.dashboard .filter-header {
    font-size: 22px;
    color: #000;
    font-weight: 600;
}
.dashboard .sidenav a:hover {
  color: #f1f1f1;
}

.dashboard .sidenav .closebtn {
  position: absolute;
  top: 3px;
  left: 25px;
  font-size: 20px;
  cursor: pointer;
}

.dashboard .sidenav .filter-body {
    max-height: calc(100% - 35px);
    overflow: auto;
}

.dashboard .sidenav .reset-link {
    position: absolute;
    top: 5px;
    right: 25px;
    font-size: 14px;
    cursor: pointer;
    color: #185CD9;
}

.dashboard .sidenav .radius-val {
    position: relative;
}

.dashboard .sidenav .radius-val .radius-valtext {
    visibility: visible;
    width: 25px;
    height: 25px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
  }
  
  .dashboard .sidenav .radius-val .radius-valtext::after {
    content: "";
    position: absolute;
    top: 97%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

.dashboard .sidenav .clear-location {
    position: absolute;
    right: 20px;
    font-size: 25px;
    top: 40px;
    cursor: pointer;
}

@media screen and (max-height: 450px) {
    .dashboard .sidenav {padding-top: 15px;}
    .dashboard .sidenav span {font-size: 18px;}
}

@media only screen and (min-width: 768px) {
    .dashboard .dashboard-more-768-pr-15 {
        padding-right: 20px;
    }

    .dashboard .dashboard-more-768-pb-15 {
        padding-bottom: 20px;
    }

    .mid-section {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .dashboard .booking-status-wrapper {
        padding-top: 0;
    }
    .dashboard .dashboard-less-768-pb-15 {
        padding-bottom: 20px;
    }

    .dashboard .search-input-wrapper {
        width: calc(100% - 75px);
    }

    .mid-section {
        padding: 15px 0 !important;
    }

    .dashboard .left-booking-item {
        border-right: 0;
    }

    .dashboard .booking-gallery {
        padding-left: 0;
        padding-top: 15px;
        border-top: 2px solid #D4DEEF;
    }

    .dashboard .test-border-768 {
        border-left: none;
        border-top: 2px solid #D4DEEF;
        margin-top: 20px;
        padding-top: 20px;
    }
    .dashboard .hide-less-768 {
        display: none;
    }
    
    .dashboard .show-less-768 {
        display: block;
    }
}

@media only screen and (max-width: 575px) {
    .profile-workshop-list .professional-wrapper .booking-item-wrapper {
        padding: 10px;
    }

    .profile-workshop-list .workshop-desktop-view-only {
        display: none;
    }

    .profile-workshop-list .workshop-mobile-view-only {
        display: block;
    }

    .profile-workshop-list .workshop-image {
        width: 100%;
    }

    .profile-workshop-list .amount-image-wrapper {
        position: absolute;
        top: 25px;
        left: 15px;
        height: 25px;
        background-repeat: no-repeat;
        color: #fff;
        font-size: 13px;
        font-family: "Poppins Medium";
        text-align: center;
        padding-top: 3px;
    }

    .dashboard .professional-near-you-wrapper {
        padding: 10px;
    }

    .dashboard .dashboard-less-768-pb-15 {
        padding-bottom: 10px;
    }

    .dashboard .appointment-details-button-wrapper {
        text-align: center;
    }

    .dashboard .appointment-number-details {
        text-align: center;
    }

    .dashboard .categories-heading .home-page-header {
        font-size: 18px;
    }

    .dashboard .professional-count {
        font-size: 12px;
        display: block;
        margin-left: 0;
        margin-top: 5px;
    }

    .dashboard .categories-heading .category-arrows {
        margin-top: 10px;
    }

    .dashboard .profile-user-details-side-by-side {
        padding: 0;
        width: 100%;
        margin-top: 5px;
    }

    .dashboard .bor-right {
        border: 0;
    }

    .dashboard .fee-text {
        padding: 0 5px !important;
        font-size: 12px;
    }

    .dashboard .categories-heading h4 {
        font-size: 18px;
    }

    .dashboard .services-list-wrapper h4 {
        font-size: 15px;
    }

    .dashboard .gallery-wrapper h4 {
        font-size: 15px;
    }

    .dashboard .profile-image-wrapper {
        width: 90px;
    }

    .dashboard .profile-image {
        height: 90px;
    }

    .dashboard .profile-other-details {
        width: calc(100% - 90px);
        display: inline-block;
        padding-left: 15px;
    }

    .dashboard .services-list-wrapper h4 {
        padding-bottom: 10px;
    }

    .dashboard .services-list-wrapper .back-button-wrapper {
        padding-top: 0 !important;
        padding-bottom: 20px;
    }

    .dashboard .services-sessions-button {
        padding: 5px 13px;
        width: 115px;
        height: 45px;
    }

    .dashboard .profile-date-time {
        font-size: 13px;
    }

    .dashboard .profile-date-time .label {
        font-size: 13px;
    }

    .dashboard .time-slot-less-575 {
        padding-top: 5px;
    }

    .dashboard .hide-less-575 {
        display: none;
    }

    .dashboard .show-less-575 {
        display: block;
    }

    /* .profile-workshop-list .professional-date-time .label {
        font-size: 12px;
    } */
}
@media only screen and (max-width: 420px) {
    .dashboard .profile-details-wrapper {
        padding: 20px 10px;
    }
    .dashboard .slot-wrapper {
        padding: 20px 10px;
    }
}
@media only screen and (max-width: 375px) {
    .dashboard .see-all-events {
        display: block;
    }
}

@media only screen and (max-width: 360px) {
    .dashboard .small-book-now-button {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 340px) {

    .dashboard .next-button-wrapper,
    .dashboard .prev-button-wrapper {
        width: 40px;
        height: 40px;
    }

    .dashboard .booking-details-change-button button {
        margin-top: 10px;
    }

    .dashboard .rating-review-buttons button {
        width: 95px !important;
    }
}