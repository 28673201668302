.forum .background-image-category {
  width: 50px;
  height: 50px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  margin: 0 auto;
  border-radius: 50%;
}
.forum .category-name {
  text-align: center;
  font-size: 11px;
  color: #000;
  font-weight: 600;
  padding-top: 5px;
  white-space: break-spaces;
  width: 100px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  display: -webkit-box;
}

.forum .categories-heading h4 {
  font-family: "Poppins Semi Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1b3467;
}

.forum .categories-wrapper {
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  /* background-color: #fff; */
}

.forum .custom-card {
  width: auto;
  display: inline-block;
  vertical-align: text-top;
  padding: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
  border-radius: 8px;
  height: 50px;
  background-color: #fff;
}

.forum .next-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  height: 100%;
  width: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0 8px 8px 0;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 110%;
  cursor: pointer;
}

.forum .prev-button-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px 0 0 8px;
  width: 35px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 110%;
  cursor: pointer;
}

.forum .next-button-wrapper button,
.forum .prev-button-wrapper button {
  margin: 35px 0;
}

.forum .categories-heading {
  padding: 15px;
}

.forum .professional-wrapper {
  margin: 5px 0;
  padding: 0;
}

.tutorial-modal .next-button-wrapper {
  height: 45px;
  width: 45px;
  color: #185bdb;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ccc;
  font-size: 14px;
}

.tutorial-modal .prev-button-wrapper {
  height: 45px;
  background-color: #ffffff;
  color: #185bdb;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.tutorial-modal .next-button-wrapper:disabled,
.tutorial-modal .prev-button-wrapper:disabled {
  background-color: #edf2ff;
  cursor: default;
  opacity: 0.5;
}

.forum .professional-wrapper-selected {
  background: #ffffff;
  border: 1.5px solid #d5e0f0;
  border-radius: 20px;
}

.forum .professional-designation-selected {
  padding: 20px 30px 10px;
}

.forum .professional-wrapper .professional-wrapper-row {
  cursor: pointer;
}

.forum .professional-name {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
}

.forum .professional-designation {
  font-size: 16px;
  color: #374561;
  font-family: "Poppins Semi Bold";
}

.forum .forum-wrapper {
  font-size: 16px;
  color: #374561;
  font-family: "Poppins Semi Bold";
}

.forum .professional-designation-not-selected {
  padding: 20px 30px;
  background: #f7f9ff;
  border: 1.5px solid #d5e0f0;
  border-radius: 20px;
}

.forum .professional-designation img {
  float: right;
}

.forum .forum-wrapper img {
  float: right;
}

.forum .professional-designation span,
.forum .professional-designation .no-of-comments {
  margin-left: 10px;
  color: #185cd9;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.forum .forum-wrapper .no-of-comments {
  margin-left: 10px;
  color: #185cd9;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.forum .professional-location {
  font-size: 12px;
}

.forum .answer {
  padding: 10px 30px 20px;
}

.forum .page-heading {
  display: inline-block;
  border-right: 2px solid #d5dff0;
  padding-right: 10px;
}

.forum .answer-text {
  padding: 10px 20px;
  border-radius: 20px;
  margin: 5px 0;
  font-family: "Poppins Light";
  width: calc(100% - 100px);
  display: inline-block;
  font-size: 14px;
  background-color: #ecf2ff;
  color: #566986;
}

.forum .answer-text .answer-date {
  color: #1b3467;
  font-size: 13px;
  font-weight: 400;
  float: right;
  font-family: "Poppins Regular";
}

.forum .question-asked-details {
  padding: 10px;
  width: calc(100% - 150px);
  display: inline-block;
  vertical-align: top;
}

.forum .question-commentedby {
  color: #1b3467;
  font-size: 18px;
  font-family: "Poppins Medium";
}

.forum .professional-exp {
  font-size: 11px;
  margin-top: 5px;
}

.forum .professional-exp span {
  float: right;
}

.forum .professional-exp img {
  margin-right: 3px;
  vertical-align: text-top;
  width: 14px;
}
.forum .clickable-span {
  cursor: pointer;
  text-decoration: underline;
}
.forum .cursor-default {
  cursor: default;
}
.forum .background-image-professional,
.forum .background-image-premium {
  width: 60px;
  height: 60px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  margin: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 7.6px 17.7333px 39.2667px #dae4f4;
  border: 5px solid #ffffff;
}

.forum .whole-categories-wrapper {
  position: relative;
}

.forum .sort-down {
  position: absolute;
  top: 20px;
  width: 20px;
  right: 25px;
  cursor: pointer;
}

.forum .language-dropdown-wrapper {
  padding: 7px 15px;
}

.forum .answer-text-span-wrapper {
  word-break: break-word;
  padding-top: 5px;
  font-weight: 400;
}

.forum .language-dropdown-wrapper .btn-link {
  text-decoration: underline;
}

.forum .language-dropdown-wrapper .btn-link:hover {
  background-color: transparent;
  color: #5867dd;
}
.forum .language-dropdown-wrapper .dropdown {
  display: inline-block;
  margin-left: 10px;
}

.forum .dropdown-menu {
  right: 0;
}

.forum .professional-near-you-wrapper {
  padding: 15px;
  box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
  background-color: #fff;
  border-radius: 8px;
}

.forum .answer-commentedby {
  color: #1b3467;
  font-size: 15px;
  margin-bottom: 5px;
  font-family: "Poppins Medium";
}

.forum .show-answers-bottom {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
}

.forum .message-input {
  margin-top: 10px;
  width: calc(100% - 100px);
  display: inline-block;
  padding: 7px 0;
  position: relative;
}

.forum .message-input input {
  border-color: #ccc;
}

.forum .message-input .textarea {
  border-radius: 20px;
  border: 1.5px solid #d5e0f0;
  padding: 15px 60px 15px 15px;
}

.forum .message-input button {
  border: none;
  position: absolute;
  right: 5px;
  top: 11px;
}

.forum .message-input button:hover {
  border: none;
  background-color: transparent;
}

.forum .answers-wrapper {
  max-height: 300px;
  overflow: auto;
  /* padding-right: 10px; */
}


.forum .forum-search-input-wrapper {
  width: 450px;
  margin: 0 auto;
  height: 40px;
  margin-bottom: 15px;
}

.forum .forum-search-input-wrapper input {
  height: 40px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.forum .forum-search-input-wrapper .input-group-append button {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.forum .qa-by-you {
  color: #185cd9;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins Medium";
  padding-left: 10px;
  display: inline-block;
}

.forum .status-text {
  font-size: 13px;
  font-family: "Poppins Medium";
  color: #d97518;
  padding-right: 10px;
}

.forum .confirmed-status {
  color: #2b9770;
}

.forum .qa-date {
  color: #1b3467;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins Regular";
  display: inline-block;
  padding-right: 10px;
  border-right: 2px solid #1b3467;
}

.forum .qa-status {
  color: #185bdb;
  font-size: 12px;
  padding-top: 10px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid blue;
}

.ask-question-modal .login-logo-wrapper {
  text-align: center;
}

.ask-question-modal .welcome-text {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.ask-question-modal .sign-in-text {
  color: #aaa;
  font-size: 12px;
}

.ask-question-modal .login-button {
  padding: 0;
  text-align: center;
}

.ask-question-modal .login-button .btn-primary,
.ask-question-modal .login-button .btn-default {
  margin-bottom: 5px;
  font-size: 18px;
  background: #185bdb;
  border: 1px solid #185bdb;
  border-radius: 50px;
  padding: 10px 20px;
  width: 150px;
  color: #ffffff;
  font-family: "Poppins Regular";
}
.ask-question-modal .login-button .btn-default {
  background: #ecf2ff;
  color: #1b3467;
  border: 0;
  margin-left: 10px;
}
.ask-question-modal .login-button .btn-link {
  font-size: 12px;
  color: #185bdb !important;
  text-decoration: underline !important;
  width: auto;
  padding-right: 0;
  padding-left: 0;
}
.ask-question-modal .login-button .btn-link:hover {
  background-color: transparent;
  color: #185bdb;
}
.ask-question-modal .login-phone-input {
  padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
  color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
  font-size: 11px;
  color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
  padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
  color: #185bdb !important;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
  border: 1px solid #ccc;
  text-align: center;
  font-size: 16px;
  color: #185bdb;
  font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
  float: left;
}

.ask-question-modal .question-info {
  margin-bottom: 15px;
  font-size: 14px;
  color: #566986;
  font-family: "Poppins Medium";
}

.ask-question-modal .question-input label {
  color: #374561;
  font-size: 16px;
  font-family: "Poppins Semi Bold";
}

.ask-question-modal .question-textarea {
  background: #eff5ff;
  border-radius: 15px;
  padding: 15px;
  color: #566986;
  font-family: "Poppins Medium";
  font-size: 14px;
}

.ask-question-modal .modal-body {
  padding: 10px 25px 25px 25px;
}
.ask-question-modal .info-wrapper {
  border-radius: 50%;
  display: inline-block;
  background-color: #d4deec;
  width: 17px;
  height: 17px;
  text-align: center;
  margin-right: 8px;
  vertical-align: middle;
}
.ask-question-modal .info-wrapper img {
  margin-bottom: 4px;
}
.ask-question-modal .question-info i {
  margin-right: 10px;
}

.ask-question-modal .modal-title {
  color: #1b3467 !important;
  font-size: 26px !important;
  font-family: "Poppins Semi Bold";
  font-weight: 600 !important;
}

.forum .z {
  color: #185cd9;
}

.forum .success-payment-wrapper {
  margin: 0px auto;
  text-align: center;
  font-size: 16px;
  max-width: 500px;
  padding: 15px;
  box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 10px -4px rgb(0, 0, 0, 0.75);
  border-radius: 8px;
}

.forum .success-payment-wrapper .header-1 {
  color: #000;
}

.forum .success-payment-wrapper .header-2 {
  color: #000;
  padding: 10px 0;
}

.forum .success-payment-wrapper .appointment-number {
  color: #185bdb;
  font-size: 18px;
  padding-bottom: 10px;
  font-weight: 800;
  letter-spacing: 10px;
}

.forum .success-payment-wrapper button {
  font-size: 14px;
}

.forum .success-payment-wrapper button.btn-primary {
  margin-left: 10px;
}

.forum.success-payment-outside {
  padding: 15px;
  transform: translateY(40%);
}

.forum .message-input img {
  width: 40px;
}

.forum .notification-date-time-wrapper {
  width: 100px;
  display: inline-block;
  font-family: "Poppins Regular";
  color: #fff;
  background-color: #185cd9;
  padding: 10px 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.forum .notification-date-time-wrapper .only-day {
  width: 40%;
  display: inline-block;
  vertical-align: top;
  font-size: 26px;
  font-family: "Poppins Semi Bold";
}

.forum .notification-date-time-wrapper .month-year-wrapper {
  width: 60%;
  display: inline-block;
  font-size: 13px;
  padding-left: 8px;
}

.forum .notification-text-wrapper {
  width: calc(100% - 100px);
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px 10px 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  align-self: center;
}

.forum .time-wrapper {
  width: 100%;
  font-family: "Poppins Regular";
  border-top: 1px solid #fff;
  font-size: 14px;
  padding-top: 4px;
  margin-top: 4px;
}

.forum .notification-wrapper {
  background-color: #f1f5ff;
  border: 0;
}
.forum .seen-notification .notification-date-time-wrapper {
  background-color: #697b97;
}

.forum .seen-notification .notification-text-wrapper {
  font-family: "Poppins Regular";
}

.forum .unseen-dot {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.test-modal .question-count {
  color: #566986;
  font-size: 14px;
  font-family: "Poppins Regular";
  font-weight: 400;
}

.test-modal .question {
  color: #1b3467;
  font-family: "Poppins Medium";
  font-weight: 600;
}

.test-modal .answer-option {
  background: #e1e7f4;
  border: 1.5px solid #d2dded;
  border-radius: 50px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Poppins Regular";
  font-weight: 400;
  color: #1b3467;
  position: relative;
  cursor: pointer;
}

.test-modal .question-type-2-content {
  text-align: center;
  font-size: 18px;
  border-bottom: 1.5px solid #ccc;
  padding: 5px 0;
  font-family: "Poppins Regular";
  font-weight: bold;
  color: #185cd9;
}

.test-modal .timer-wrapper {
  position: absolute;
  bottom: 0;
  right: -6px;
}
.test-modal .pb-70 {
  padding-bottom: 70px !important;
}
.test-modal .timer-header {
  padding-right: 5px;
  font-size: 11px;
  font-weight: 400;
}

.test-modal .timer-value {
  background-color: #185cd9;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
}

.test-modal .option-radio {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 25px;
  height: 25px;
}

.test-modal .answer-selected {
  background-color: #185cd9;
  color: #fff;
}

.test-modal .confirmation-header {
  margin: 20px 0 10px;
  color: #185cd9;
  font-family: "Poppins Medium";
}

.test-modal .confirmation-text {
  color: #1b3467;
  font-size: 14px;
  font-family: "Poppins Medium";
  font-weight: 400;
  margin-bottom: 30px;
}

.test-modal .pre-confirmation-header {
  color: #1b3467;
  font-family: "Poppins Medium";
  font-weight: 400;
  font-size: 16px;
}

.test-modal .pre-confirmation-text {
  color: #566986;
  font-family: "Poppins Regular";
  font-weight: 400;
  font-size: 12px;
}

.test-modal .pre-confirmation-description {
  color: #566986;
  font-family: "Poppins Regular";
  font-weight: 400;
  font-size: 14px;
}

.test-modal .pre-confirmation-disclaimer-header {
  color: #566986;
  font-family: "Poppins Medium";
  font-weight: 400;
}

.test-modal .pre-confirmation-disclaimer-text {
  color: #566986;
  font-family: "Poppins Regular";
  font-weight: 400;
  font-size: 11px;
}

.test-modal .test-category-item {
  width: 100%;
  margin: 8px 0;
  font-size: 13px;
  font-family: "Poppins Regular";
  font-weight: 400;
  cursor: pointer;
}

.test-modal .test-category-item i {
  margin-right: 5px;
  color: #374561;
}

.test-modal .close-modal {
  margin-top: 10px;
  font-size: 18px;
  cursor: pointer;
}

.test-modal .scrollable-modal {
  max-height: 400px;
  overflow: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.test-modal .modal-body {
  padding: 10px 5px 25px !important;
}

.forum .remove-question-icon,
.forum .answer-question-icon {
  position: absolute;
  font-size: 23px;
  right: 0;
  top: -10px;
  color: #f00;
}

@media only screen and (min-width: 768px) {
  .forum .forum-more-768-pr-15 {
    padding-right: 15px;
  }
  .forum .forum-more-768-pb-15 {
    padding-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .forum .forum-less-768-pb-15 {
    padding-bottom: 15px;
  }
  .forum .forum-search-input-wrapper {
    width: 96%;
  }
  .forum-upper-button-wrapper .my-qa,
  .forum-upper-button-wrapper .ask-question {
    font-size: 12px;
    padding: 5px;
  }
  .forum-upper-button-wrapper .ask-question {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 575px) {
  .forum-upper-button-wrapper .my-qa,
  .forum-upper-button-wrapper .ask-question {
    font-size: 11px;
  }
  .forum .professional-near-you-wrapper {
    padding: 10px;
  }
  .forum .forum-less-768-pb-15 {
    padding-bottom: 10px;
  }
  .forum .categories-heading {
    padding: 15px 10px;
  }
  .forum .answer,
  .forum .professional-designation-selected,
  .forum .professional-designation-not-selected {
    padding: 10px;
  }
  .forum .qa-date,
  .forum .qa-by-you,
  .forum .status-text {
    font-size: 12px;
  }
  .forum .professional-designation {
    font-size: 15px;
  }
  .forum .forum-wrapper {
    font-size: 15px;
  }
  .forum .background-image-professional,
  .forum .background-image-premium {
    width: 50px;
    height: 50px;
  }
  .forum .answer-text {
    padding: 15px;
    width: calc(100% - 70px);
    font-size: 13px;
  }
  .forum .message-input {
    width: calc(100% - 70px);
  }
  .forum .answer-commentedby {
    font-size: 14px;
  }
  .forum .answer-text .answer-date {
    font-size: 12px;
  }
  .forum .message-input .textarea {
    padding: 15px 45px 15px 15px;
  }
  .forum .message-input img {
    width: 30px;
  }
  .forum .message-input button {
    right: 5px;
    top: 18px;
  }
  .forum .categories-heading h4 {
    font-size: 18px;
  }
  .forum .ask-question-modal .modal-body {
    padding: 15px 25px;
  }
  .forum .ask-question-modal .modal-title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 365px) {
  .forum .success-payment-wrapper button {
    width: 160px;
    margin-bottom: 5px;
  }

  .forum .success-payment-wrapper button.btn-primary {
    margin-left: 0;
  }
}
