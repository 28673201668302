.custom-navigation-bar {
    background-color: #ECF2FF;
}

.custom-left-navigation-bar {
    background-color: #fff;
    margin: 0 auto;
}

.custom-right-navigation-bar {
    background-color: #fff;
    text-align: right;
    padding-right: 40px !important;
}

.custom-navigation-bar .login-button {
    padding: 13px 0.5rem !important;
}

.dropdown-menu-opened {
    position: absolute !important;
    right: 0;
    left: auto !important;
}
.font-12 {
    font-size: 12px;
}
.custom-navigation-bar .nav-link {
    color: #185BDB !important;
}
.main-page-wrapper {
    max-width: 1000px !important;
    margin: 0 auto;
    padding: 15px 0 !important;
}

.full-main-wrapper {
    max-width: 100% !important;
    padding: 0 !important;
}
.show-login-modal {
    display: block !important;
    background-color: rgba(0,0,0,0.5);
}

.login-modal .login-logo-wrapper {
    text-align: center;
}

.login-modal .welcome-text {
    color: #1B3467;
    font-weight: 600;
    font-family: 'Poppins Semi Bold';
    font-size: 26px;
}

.login-modal .sign-in-text {
    color: #566986;
    font-size: 12px;
    font-family: 'Poppins Regular';
}

.login-modal .login-button {
    padding: 15px 10px;
    text-align: center;
}

.login-modal .login-button .btn-primary {
    margin-bottom: 5px;
    font-size: 15px;
    background: #185BDB;
    border: 1px solid #185BDB;
    border-radius: 50px;
    padding: 10px 25px;
    width: 150px;
    color: #ffffff;
    font-family: 'Poppins Semi Bold';
}
.login-modal .login-modal-close {
    cursor: pointer;
    width: 30px;
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 99;
}
.login-modal .login-button .btn-link {
    font-size: 14px;
    color: #185BDB !important;
    text-decoration: underline !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    display: block;
    margin: 0 auto;
    font-family: 'Poppins Semi Bold';
}
.login-modal .login-button .btn-link:hover {
    background-color: transparent;
    color: #185BDB;
}
.login-modal .login-phone-input {
    padding: 20px 10px 0;
}

.login-modal .login-phone-input .input-group {
    border-radius: 15px;
}

.login-modal .login-phone-input .phone-number-input {
    background-color: #EFF5FF;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
    height: 45px;
}

.login-modal .login-phone-input .input-group-text {
    color: #566986;
    background-color: #EFF5FF;
    font-family: 'Poppins Medium';
}

.login-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.login-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.login-modal .accept-checkbox-wrapper span {
    color: #185BDB !important;
    cursor: pointer;
}

.login-modal .modal-body {
    padding: 25px;
}

.login-modal .login-otp-input-wrapper .form-control {
    font-weight: 600;
    background-color: #EFF5FF;
    color: #566986;
    font-size: 14px;
    height: 45px;
}

.login-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .login-button {
    padding: 0 !important;
}

.ask-question-modal .question-input label {
    color: #374561;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}

.ask-question-modal .modal-body {
    padding: 10px 25px 25px 25px;
}

.ask-question-modal .modal-title {
    color: #1B3467 !important;
    font-size: 26px !important;
    font-family: 'Poppins Semi Bold';
    font-weight: 600 !important;
}

.ask-question-modal .form-check {
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-right: 10px;
}

.ask-question-modal .question-input {
    margin-top: 30px;
    margin-bottom: 30px;
}

.ask-question-modal .file-uploader {
    width: 40px;
    border: 1px solid #ccc;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.ask-question-modal .file-uploader i {
    font-size: 20px;
    vertical-align: middle;
    line-height: inherit;
}

.ask-question-modal .form-check-label {
    padding-left: 10px;
    color: #566986;
    font-family: 'Poppins Regular';
}
.custom-navigation-bar .navbar-brand {
    padding: 0;
}
.nav-logo {
    width: 40px;
    height: 40px;
}

.birth-day-date-picker {
    position: relative;
}
.mandatory-doc-message{
    font-size: 12px;
    font-weight: 600;
    color: red;
}
.profile-modal .custom-tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 40px;
    right: 0;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .profile-modal .custom-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .profile-modal .custom-tooltip .show-tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.birth-day-date-picker i {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 16px;
    cursor: pointer;
}

.custom-navigation-bar .custom-left-navigation-bar {
    padding-bottom: 0;
    padding-top: 0;
    background-color: #ECF2FF;
}

.custom-left-nav .nav-link {
    padding-right: 25px !important;
}

.custom-navigation-bar .nav-link {
    color: #566986 !important;
    font-family: 'Poppins Regular';
    padding: 20px 0.5rem;
}

.custom-navigation-bar .nav-item {
    padding: 0 5px;
}

.navbar-nav .fa-bell {
    font-size: 18px;
    margin-right: 10px;
}
.profile-modal .profile-image-wrapper {
    height: 100px;
}
.profile-modal .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 50%;
    width: 100px;
    margin: 0 auto;
    position: relative;
}
.profile-modal .btn-primary {
    margin-bottom: 5px;
    font-size: 15px;
    background: #185BDB;
    border: 1px solid #185BDB;
    border-radius: 50px;
    padding: 10px 25px;
    width: 100px;
    color: #ffffff;
    font-family: 'Poppins Semi Bold';
}
.profile-modal .profile-phone-number {
    color: #A1B2D0;
    font-size: 16px;
    font-family: 'Poppins Medium';
}
.profile-modal .profile-inputs {
    background-color: #EFF5FF;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
    height: 45px;
}
.profile-modal .profile-name {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.profile-modal .MuiTextField-root {
    width: 100%;
}

.profile-modal .MuiInput-input {
    background-color: #EFF5FF;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
    height: 35px;
    padding: 6px 10px;
}

.profile-modal .MuiInput-underline:before {
    border-bottom: 0;
}

.profile-modal .profile-designation {
    color: #185BDB;
    font-size: 14px;
    margin-top: 5px;
}

.profile-modal .profile-exp {
    color: #aaa;
    font-size: 14px;
    margin: 5px 0;
}

.profile-modal .file-uploader {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.7);
    border-radius: 50%;
    border: 2px solid #fff;
}

.profile-modal .file-uploader i {
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    vertical-align: middle;
}

.profile-modal .accept-checkbox-wrapper {
    margin-top: 5px;
}

.custom-nav-bar .profile-image-wrapper {
    height: 40px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    position: relative;
    margin-top: 8px;
}
.custom-nav-bar .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 50%;
    width: 40px;
    margin: 0 auto;
    position: relative;
}

.custom-nav-bar .profile-image-wrapper .icon-div-wrapper {
    position: absolute;
    right: -6px;
    top: 13px;
    border-radius: 50%;
    color: #185BDB;
    width: 15px;
    height: 15px;
    background-color: #fff;
    text-align: center;
    line-height: 1.3;
}

@media only screen and (max-width: 575px) {
    .login-modal .modal-body {
        padding: 25px;
    }
    .custom-login-modal .modal-sm {
        max-width: 300px;
        margin: 0.5rem auto;
    }

    .profile-modal .modal-sm {
        max-width: 300px;
        margin: 0.5rem auto;
    }
}