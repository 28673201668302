.problem-area .background-image-category {
  width: 50px;
  height: 50px;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  margin: 0 auto;
  border-radius: 50%;
}

.problem-area .category-name {
  text-align: center;
  font-size: 13px;
  color: #185cd9;
  font-weight: 600;
  padding-top: 5px;
  white-space: break-spaces;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  display: -webkit-box;
  font-family: "Poppins Regular";
}

.problem-area .categories-wrapper {
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* background-color: #fff; */
}

.problem-area .categories-wrapper::-webkit-scrollbar {
  display: none;
}

.problem-area .custom-card {
  width: 130px;
  display: inline-block;
  vertical-align: text-top;
  padding: 6px 5px;
  margin-right: 15px;
  box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
  -webkit-box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
  -moz-box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
  border-radius: 15px;
  height: 80px;
  background-color: #fff;
  cursor: pointer;
}

.problem-area .next-button-wrapper {
  height: 45px;
  width: 45px;
  color: #185bdb;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e6ecf8;
  font-size: 14px;
}

.problem-area .prev-button-wrapper {
  height: 45px;
  background-color: #ffffff;
  color: #185bdb;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid #e6ecf8;
  font-size: 14px;
}

.problem-area .next-button-wrapper:disabled,
.problem-area .prev-button-wrapper:disabled {
  background-color: #edf2ff;
  cursor: default;
  opacity: 0.5;
}

.problem-area .next-button-wrapper:hover,
.problem-area .prev-button-wrapper:hover {
  border: 1px solid #e6ecf8;
  filter: none;
}

.problem-area .next-button-wrapper button,
.problem-area .prev-button-wrapper button {
  margin: 35px 0;
}

.problem-area .categories-heading {
  padding: 15px;
}

.problem-area .professional-wrapper {
  padding: 0;
}

.problem-area .problem-category-heading {
  color: #1b3467;
  font-size: 20px;
  font-family: "Poppins Semi Bold";
}

.problem-area .common-problem-heading {
  color: #185cd9;
  font-size: 20px;
  font-family: "Poppins Semi Bold";
}

.problem-area .professional-wrapper .row {
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  border: 1.5px solid #d5e0f0;
  border-radius: 20px;
}

.problem-area .professional-wrapper .faq-wrapper-row {
  padding: 20px 0;
  cursor: pointer;
  margin-bottom: 0;
  border: 0;
  border-bottom: 2px solid #edf1fc;
  border-radius: 0;
}

.problem-area .professional-wrapper .booking-item-wrapper {
  background-color: #eff5ff;
  padding: 20px;
  cursor: default;
}

.problem-area .professional-name {
  font-weight: 600;
  color: #374561;
  font-size: 15px;
  margin-bottom: 5px;
  font-family: "Poppins Medium";
  word-break: break-all;
}

.problem-area .professional-designation {
  font-size: 13px;
  color: #185cd9;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "Poppins Regular";
  word-break: break-all;
}

.problem-area .professional-designation img {
  float: right;
}

.problem-area .professional-location {
  font-size: 13px;
  color: #566986;
  font-family: "Poppins Regular";
}

.problem-area .answer {
  font-size: 14px;
  cursor: default;
  padding: 0 20px;
  color: #566986;
  font-family: "Poppins Regular";
}

.problem-area .question-text {
  display: inline-block;
  width: calc(100% - 20px);
  vertical-align: top;
}

.problem-area .professional-exp {
  font-size: 11px;
  margin-top: 5px;
}

.problem-area .professional-exp span {
  float: right;
}

.problem-area .professional-exp img {
  margin-right: 3px;
  vertical-align: text-top;
  width: 14px;
}

.problem-area .background-image-professional,
.problem-area .background-image-premium {
  width: 50px;
  height: 50px;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  margin: 10px auto;
  border-radius: 50%;
}

.problem-area .whole-categories-wrapper {
  position: relative;
  padding: 0 15px;
}

.problem-area .sort-down {
  position: absolute;
  top: 20px;
  width: 20px;
  right: 25px;
  cursor: pointer;
}

.problem-area .language-dropdown-wrapper {
  padding: 15px;
}

.problem-area .btn-secondary,
.problem-area .btn.btn-secondary:hover {
  font-size: 14px;
  background-color: #edf2ff;
  border-radius: 10px;
  padding: 9px 20px;
  color: #566986;
  font-family: "Poppins Medium";
  filter: none;
}

.problem-area .language-dropdown-wrapper .btn-link {
  text-decoration: underline;
}

.problem-area .language-dropdown-wrapper .btn-link:hover {
  background-color: transparent;
  color: #5867dd;
}

.problem-area .language-dropdown-wrapper .dropdown {
  display: inline-block;
  margin-left: 10px;
}

.problem-area .language-dropdown-wrapper .dropdown-item {
  padding: 7px 10px;
}

.problem-area .language-dropdown-wrapper .item-border {
  border-bottom: 2px solid #ecf0fc;
}

.problem-area .dropdown-menu {
  right: 0;
  left: unset;
  padding: 10px;
}

.problem-area .dropdown-menu span {
  cursor: pointer;
}

.problem-area .professional-near-you-wrapper {
  padding: 15px 0;
  background-color: #fff;
  border-radius: 8px;
}

.problem-area .read-out {
  /* border: 1px solid #ccc; */
  padding: 10px;
  /* border-radius: 8px; */
  color: #374561;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Poppins Medium";
}

.problem-area .faq-question i {
  float: right;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.problem-area .faq-question {
  font-size: 15px;
  color: #1b3467;
  font-family: "Poppins Medium";
}

.problem-area .bor-bot {
  color: #185bdb;
  font-family: "Poppins Semi Bold";
  padding-bottom: 15px;
}

.faq .category-name {
  padding-top: 8px;
}

.booking-list .professional-name {
  margin-bottom: 0;
  font-size: 16px;
  word-break: break-all;
}

.modal-buttons .btn-primary {
  font-size: 14px;
  border-radius: 10px;
}

.booking-list .profile-exp {
  color: #566986;
  font-size: 14px;
  font-family: "Poppins Regular";
}

.booking-list .booking-item-side-row {
  padding: 0 !important;
  cursor: default !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.booking-list .professional-service-desc {
  font-size: 12px;
  color: #566986;
  font-family: "Poppins Regular";
  margin: 5px 0;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.booking-list .professional-amount {
  font-size: 14px;
  color: #185bdb;
  font-family: "Poppins Medium";
  margin-top: 5px;
}

.booking-list .professional-date-time {
  color: #566986;
  font-size: 14px;
  font-family: "Poppins Regular";
}

.booking-list .professional-date-time .label {
  color: #1b3467;
  font-size: 14px;
  font-family: "Poppins Medium";
  margin-bottom: 5px;
}

.booking-list .professional-date-time .fees-invoice-event-details {
  color: #1b3467;
  font-size: 14px;
  font-family: "Poppins Medium";
  margin-bottom: 5px;
  border-top: 1px solid #ccc;
  margin-top: 5px;
  padding-top: 5px;
}

.booking-list .show-less-event-details-invoice {
  background-color: #ecf2ff;
  border-radius: 20px !important;
  padding: 15px !important;
}

.booking-list .event-details-fee {
  color: #566986;
  font-size: 13px;
  word-break: break-all;
  font-weight: 500;
  font-family: "Poppins Regular";
}

.booking-list .details-button {
  float: right;
  box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  border-radius: 50px;
  border: 1px solid #185bdb;
  padding: 10px 20px;
}

.booking-list .upcoming-past-button {
  border-radius: 50px;
  border: 1px solid #f4f8ff;
  color: #566986;
  padding: 10px 20px;
  background-color: #f4f8ff !important;
  font-family: "Poppins Regular";
  margin-right: 10px;
}

.booking-list .upcoming-past-button.active {
  border: 1px solid #e6ecf8;
  color: #185bdb !important;
  background-color: #ffffff !important;
  box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  border: 1px solid #e6ecf8;
  font-family: "Poppins Medium";
}

.booking-list .upcoming-past-button:hover {
  filter: none;
  color: inherit;
}

.booking-list .professional-designation {
  color: #566986;
  font-size: 14px;
  margin-top: 5px;
  word-break: break-all;
}

.booking-list .professional-location {
  color: #000;
  font-size: 13px;
  word-break: break-all;
}

.booking-list .professional-exp {
  color: #000;
  font-size: 12px;
}

.booking-list .professional-time {
  color: #185bdb;
  font-size: 12px;
}

.booking-list .booking-profile-image {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.booking-list .booking-profile-image .background-image-professional {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0;
  border: 4px solid #ffffff;
  box-shadow: 6px 14px 31px #dae4f4;
  -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
  -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.booking-list .mobile-view-only {
  display: none;
}

.booking-list .desktop-view-only {
  display: block;
}

.workshop-list .workshop-mobile-view-only {
  display: none;
}

.workshop-list .workshop-desktop-view-only {
  display: block;
}

.booking-list .booking-details-wrapper {
  width: calc(100% - 80px);
  display: inline-block;
  padding-left: 10px;
}

.booking-list .nav-pills .nav-link {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #5d78ff !important;
  padding: 10px 40px !important;
}

.booking-list .nav-pills .active {
  color: #fff !important;
}

.problem-area .ptb-5 {
  padding-top: 10px;
  padding-bottom: 5px;
}

.booking-list .upcoming-past-heading {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  font-size: 16px;
  color: #185bdb;
}

.problem-area .problem-question {
  font-size: 18px;
  color: #374561;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Poppins Semi Bold";
}

.problem-area .problem-category {
  font-size: 14px;
  color: #185cd9;
  margin-bottom: 15px;
  position: relative;
  font-family: "Poppins Regular";
}

.problem-area-details h4 {
  font-family: "Poppins Semi Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1b3467;
}

.problem-area-details {
  padding: 15px 25px;
}

.problem-area .problem-answer {
  font-size: 16px;
  color: #566986;
  margin-bottom: 15px;
  font-family: "Poppins Regular";
}

.problem-area .problem-details-wrapper {
  margin: 0 auto;
  line-height: 30px;
}

.problem-area-details .fa-arrow-left {
  margin-right: 15px;
  cursor: pointer;
}

.problem-area .problem-category img {
  position: absolute;
  right: 5px;
  top: 0;
}

.problem-area .selected-card {
  background-color: #185cd9;
}

.problem-area .selected-card-name {
  color: #fff;
}

.booking-list .profile-icon-wrapper i,
.booking-list .profile-icon-wrapper img {
  margin-right: 10px;
  cursor: pointer;
}

.booking-list .profile-icon-wrapper {
  margin: 5px 0;
}

.booking-list .profile-icon-wrapper i {
  color: #185bdb;
  font-size: 14px;
  vertical-align: middle;
}

.booking-list .profile-icon-wrapper img {
  width: 16px;
}

.booking-list .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 9;
}

.booking-list .tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.booking-list .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.booking-list .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.booking-list .professional-address-title {
  color: #374561;
  font-size: 14px;
  font-family: "Poppins Medium";
}

.booking-list .pr-20 {
  padding-right: 20px;
}

.booking-list .pl-20 {
  padding-left: 20px;
}

.booking-list .left-booking-item {
  border-right: 2px solid #d4deef;
  margin-bottom: 10px;
}

.booking-list .profile-rating {
  font-size: 13px;
  color: #566986;
}

.booking-list .profile-rating i {
  color: #e12936;
}

.booking-list .event-list-wrapper {
  padding-left: 30px;
}

.booking-list .event-list-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.booking-list .event-list-img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /* border-radius: 20px; */
}

.booking-list .mb-30 {
  margin-bottom: 30px;
}

.booking-list .event-list-item-wrapper {
  background-color: #fff !important;
  padding: 0 !important;
  border: none !important;
}

.booking-list .event-list-item-description-wrapper {
  /* border-right: 1.5px solid #D5E0F0;
    border-left: 1.5px solid #D5E0F0;
    border-bottom: 1.5px solid #D5E0F0; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ecf2ff;
  padding: 20px !important;
}

.booking-list .amount-image-wrapper {
  position: absolute;
  top: 20px;
  left: 0;
  width: 110px;
  height: 30px;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins Medium";
  text-align: center;
  padding-top: 3px;
}

.booking-list .event-list-image-wrapper {
  width: 100%;
  height: 200px;
  position: relative;
}

.booking-list .event-details-image-wrapper {
  width: 100%;
  height: 400px;
  position: relative;
}

.booking-list .event-list-item-wrapper .carousel-root {
  width: 100%;
  height: 400px;
}

.booking-list .event-list-item-wrapper .carousel-root * {
  height: 100%;
}

.booking-list .event-list-item-wrapper .carousel-root img {
  border-radius: 20px;
}

.booking-list .event-list-item-wrapper .carousel .control-next.control-arrow {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  top: 44%;
  right: 10px;
  opacity: 0.7;
  background: rgba(0, 0, 0, 0.7);
}

.booking-list .event-list-item-wrapper .carousel .control-prev.control-arrow {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  top: 44%;
  left: 10px;
  opacity: 0.7;
  background: rgba(0, 0, 0, 0.7);
}

.booking-list .event-list-item-wrapper .carousel .control-next.control-arrow::before {
  margin: 10px 15px;
}
.booking-list .event-list-item-wrapper .carousel .control-prev.control-arrow::before {
  margin: 10px 14px;
}

.booking-list .event-details-image-nav-wrapper {
  position: absolute;
  bottom: 10px;
  left: 45%;
}

.carousel-item img {
  transition: all 1s;
  transform: scale(1);
}

.carousel-item.active img {
  transform: scale(1.2);
}

.booking-list .fee-text {
  font-weight: 600;
  color: #374561;
  font-family: "Poppins Regular";
  display: inline-block;
  font-size: 13px;
  padding: 0 10px;
  word-break: break-all;
}

.booking-list .seat-count {
  color: #185bdb;
}

.booking-list .bor-right,
.workshop-details .bor-right-always {
  border-right: 1px solid #ccc;
}

.workshop-details .small-section {
  padding: 0 0 0 10px;
}

.booking-list .hide-less-575 {
  display: block;
}

.booking-list .show-less-575 {
  display: none;
}

.booking-list .event-details-button {
  margin-top: -10px;
}

.booking-list .categories-heading .navbar-nav {
  position: absolute;
  top: 6px;
  right: 0;
  width: 50px;
  cursor: pointer;
}

.booking-list .total-seats-text {
  font-size: 16px !important;
  color: #979fae !important;
}

.booking-list .available-seats-text {
  color: #374561 !important;
  font-size: 16px !important;
}

.booking-list .margin-top-5 {
  margin-top: 5px;
}

.booking-list .event-book-now-button {
  margin-top: -10px;
}

.forum-wrapper .professional-designation-selected {
  padding: 20px 30px 10px;
}

.forum-wrapper .professional-designation {
  font-size: 16px;
  color: #374561;
  font-family: "Poppins Semi Bold";
}

.forum-wrapper .professional-designation-not-selected {
  padding: 20px 30px;
  background: #f7f9ff;
  border: 1.5px solid #d5e0f0;
  border-radius: 20px;
}

.forum-wrapper .professional-designation img {
  float: right;
}

.forum-wrapper .professional-designation span {
  margin-left: 10px;
  color: #185cd9;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.forum-wrapper .background-image-professional {
  width: 60px;
  height: 60px;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  margin: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 7.6px 17.7333px 39.2667px #dae4f4;
  border: 5px solid #ffffff;
}

.forum-wrapper .question-asked-details {
  padding: 10px;
  width: calc(100% - 100px);
  display: inline-block;
  vertical-align: top;
}

.forum-wrapper .question-commentedby {
  color: #1b3467;
  font-size: 18px;
  font-family: "Poppins Medium";
}

.forum-wrapper .professional-wrapper-selected {
  background: #ffffff;
  border: 1.5px solid #d5e0f0;
  border-radius: 20px;
}

.forum-wrapper .answers-wrapper {
  max-height: 300px;
  overflow: auto;
  /* padding-right: 10px; */
}

.forum-wrapper .answer-text {
  padding: 10px 20px;
  border-radius: 20px;
  margin: 5px 0;
  font-family: "Poppins Light";
  width: calc(100% - 100px);
  display: inline-block;
  font-size: 14px;
  background-color: #ecf2ff;
  color: #566986;
}

.forum-wrapper .answer-text .answer-date {
  color: #1b3467;
  font-size: 13px;
  font-weight: 400;
  float: right;
  font-family: "Poppins Regular";
}

.forum-wrapper .answer-text-span-wrapper {
  word-break: break-all;
  padding-top: 5px;
  font-weight: 400;
}

.forum-wrapper .answer-commentedby {
  color: #1b3467;
  font-size: 15px;
  margin-bottom: 5px;
  font-family: "Poppins Medium";
}

.forum-wrapper .message-input {
  margin-top: 10px;
  width: calc(100% - 100px);
  display: inline-block;
  padding: 7px 0;
  position: relative;
}

.forum-wrapper .message-input input {
  border-color: #ccc;
}

.forum-wrapper .message-input .textarea {
  border-radius: 20px;
  border: 1.5px solid #d5e0f0;
  padding: 15px 60px 15px 15px;
}

.forum-wrapper .message-input button {
  border: none;
  position: absolute;
  right: 5px;
  top: 11px;
}

.forum-wrapper .message-input button:hover {
  border: none;
  background-color: transparent;
}

.forum-wrapper .message-input img {
  width: 40px;
}

.forum-wrapper .answer {
  padding: 10px 30px 20px;
}

.fav-professional-wrapper .row {
  padding: 15px;
  background: #ecf2ff;
  border-radius: 20px;
  cursor: pointer;
}

.event-share-button-wrapper {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 9;
  cursor: pointer;
  /* background-color: white;
    color: blue;
    z-index: 9;
    border-radius: 50%;
    padding: 7px 10px; */
}

.problem-area .bottom-modal {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: inherit;
  margin-bottom: 0;
  margin-left: 0;
}

.problem-area .bottom-modal .modal-content {
  border-radius: 0;
}

.problem-area .open-app-modal-container .welcome-text {
  color: #566986;
  font-size: 18px;
  font-family: "Poppins Regular";
  font-weight: 500;
}

.problem-area .open-app-modal-container .company-name {
  color: #1b3467;
  font-size: 22px;
  font-family: "Poppins Regular";
  font-weight: 600;
}

.problem-area .open-app-modal-container .open-app-primary-button {
  box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
  border-radius: 50px;
  border: 1px solid #185bdb;
  padding: 10px 25px;
  font-size: 14px;
}

.problem-area .open-app-modal-container .btn-link {
  text-decoration: none;
  font-size: 13px;
}

.test-list .age-info {
  background-color: #0A1935;
  width: 100%;
  z-index: 9;
  position: absolute;
  opacity: 0.9;
  height: 35px;
  bottom: 0;
  color: #fff;
  padding: 7px 20px;
  font-family: 'Poppins Regular';
  font-size: 14px;
}

.test-list .test-description {
  font-size: 12px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins Regular";
  /* height: 40px; */
}

.test-list .test-category {
  font-size: 17px;
  color: #374561;
  font-weight: 600;
  font-family: "Poppins Semi Bold";
  padding-left: 15px;
}

.test-list .test-category span {
  font-size: 11px;
}

.test-list .test-text {
  padding: 0 !important;
  cursor: pointer;
  border: 0 !important;
  border-radius: 0 !important;
}

.test-details .test-form-wrapper {
  background-color: #ecf2ff;
  padding: 30px 20px;
  border-radius: 20px;
  margin: 0 auto;
}

.test-details .test-completed-booking-wrapper {
  background-color: #ecf2ff;
  padding: 20px;
  border-radius: 20px;
}

.test-details .test-completed-booking-wrapper.wrapper-1 {
  max-width: 48%;
  margin-right: 10px;
}

.test-details .test-completed-booking-wrapper.wrapper-2 {
  color: #abb7cb;
  background-color: #374561;
  padding: 0;
}

.test-details .test-completed-booking-wrapper.wrapper-2 .invoice-labels {
  padding-left: 20px;
  color: #abb7cb;
}

.test-details .test-completed-booking-wrapper.wrapper-2 .value {
  color: #fff;
  padding-right: 20px;
}

.test-details .test-completed-booking-wrapper.wrapper-2 .info {
  font-size: 12px;
  background-color: rgb(10, 25, 53);
  margin-top: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 20px;
  color: #fff;
}

.test-details .form-check {
  display: inline-block;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  margin-right: 10px;
}

.test-details .invoice-labels {
  font-weight: 400;
  font-family: "Poppins Regular";
  color: #566986;
}

.test-details .invoice-total {
  color: #1b3467;
  font-weight: 400;
  font-family: "Poppins Medium";
}

.test-details .prof-card {
  height: 120px;
  background-color: #ecf2ff;
  padding: 10px 5px;
  box-shadow: none;
  color: #1b3467;
  width: 120px;
}

.test-details .prof-card .category-name {
  color: #1b3467;
  padding-top: 7px;
}

.ask-question-modal .scrollable-modal {
  max-height: 400px;
  overflow: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.workshop-list .workshop-image {
  width: 200px;
  height: 180px;
}

.workshop-list .workshop-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.workshop-list .workshop-details-wrapper {
  width: calc(100% - 200px);
}

.workshop-list .amount-image-wrapper {
  position: inherit;
  top: 0;
  left: 0;
  height: 25px;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 13px;
  font-family: "Poppins Medium";
  text-align: left;
  padding-top: 3px;
  padding-left: 10px;
}

.h-240 {
  height: 240px;
}

.workshop-details .full-wrapper {
  background-color: #ECF2FF;
  padding: 20px;
  border-radius: 20px;
}

.workshop-details .professional-near-you-wrapper,
.workshop-details .event-list-item-wrapper {
  padding: 0;
  background-color: #ecf2ff !important;
}

.workshop-details .share-button-wrapper {
  position: absolute;
  top: 25px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
  background-color: #185bdb;
  width: 45px;
  height: 45px;
  color: #fff;
  font-size: 22px;
  border-radius: 50%;
  text-align: center;
  padding: 7px;
} 

.workshop-details .tooltip-info {
  position: relative;
}

.workshop-details .tooltip-info .tooltip-info-text {
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  margin-left: 5px;
  font-family: "Poppins Regular";
  font-size: 12px;
}

.workshop-details .date-section {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.workshop-details .description-wrapper {
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
}

.workshop-details .about-header {
  color: #374561;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins Medium";
}

.workshop-details .hide-row-less-575 {
  display: flex;
}

.workshop-details .show-row-less-575 {
  display: none;
}

.workshop-details .fee-text {
  color: #185bdb;
}

.workshop-details .details-button {
  float: inherit;
  margin-top: 10px;
}

.booking-list .rated {
  color: #1b3467;
  margin-top: 5px;
}

.booking-list .rating {
  border: 1px solid #185bdb;
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 3px;
}

.booking-list .rated .fa-star {
  color: #E12936;
  font-size: 12px;
}

.booking-list .rate-button {
  margin-top: 10px;
  margin-left: 3px;
}

.booking-list .rate-button button {
  font-size: 12px;
  padding: 7px;
}


.booking-list .review-image-wrapper {
  width: 50px;
  display: inline-block;
}

.booking-list .review-section {
  background-color: #ECF2FF;
  padding: 10px;
  border-radius: 8px;
}

.booking-list .booking-status-wrapper {
  padding-top: 40px;
}

.booking-list .review-other-details {
  width: calc(100% - 50px);
  display: inline-block;
  padding-left: 15px;
  text-align: left;
  vertical-align: top;
}

.booking-list .review-image {
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  border-radius: 50%;
  margin: 0;
  border: 4px solid #ffffff;
  box-shadow: 6px 14px 31px #dae4f4;
  -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
  -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
  height: 50px;
}

@media only screen and (min-width: 768px) {
  .problem-area .problem-more-768-pr-15 {
    padding-right: 15px;
  }

  .problem-area .problem-more-768-pb-15 {
    padding-bottom: 15px;
  }

  .fav-professional-wrapper .dashboard-more-768-pb-15 {
    padding-bottom: 20px;
  }

  .fav-professional-wrapper .dashboard-more-768-pr-15 {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 810px) and (min-width: 768px) {
  .booking-list .professional-date-time {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 575px) {
  .booking-list .professional-date-time {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
  .problem-area .problem-more-768-pr-15-for-2 {
    padding-right: 15px;
  }

  .problem-area .problem-more-768-pb-15-for-2 {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .booking-list .booking-status-wrapper {
    padding-top: 0;
  }
  .problem-area .mt-less-768 {
    margin-top: 10px;
  }

  .booking-list .mobile-view-only {
    display: block;
  }

  .booking-list .desktop-view-only {
    display: none;
  }

  .booking-list .event-list-image-wrapper {
    height: 150px;
  }

  .fav-professional-wrapper .dashboard-less-768-pb-15 {
    padding-bottom: 20px;
  }

  .test-details .test-completed-booking-wrapper.wrapper-1 {
    max-width: 100%;
    margin-right: 0;
  }
}

@media only screen and (max-width: 680px) {
  .booking-list .event-details-cancellation-policy-link {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 560px) and (max-width: 575px) {
  .problem-area .upcoming-past-button {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .workshop-details .small-section {
    padding: 0;
  }
  .workshop-list .professional-wrapper .booking-item-wrapper {
    padding: 10px;
  }
  .workshop-details .small-slot {
    padding: 0 5px;
  }
  .workshop-details .about-header {
    font-size: 12px;
  }
  .workshop-details .share-button-wrapper {
    top: 15px;
    right: 10px;
    width: 35px;
    height: 35px;
    font-size: 16px;
    padding: 5px;
  }
  .booking-list .event-list-item-wrapper .carousel .control-next.control-arrow,
  .booking-list .event-list-item-wrapper .carousel .control-prev.control-arrow {
    top: 38%;
    width: 35px;
    height: 35px;
  }
  .booking-list .event-list-item-wrapper .carousel .control-next.control-arrow::before {
    margin: 6px 11px;
  }
  .booking-list .event-list-item-wrapper .carousel .control-prev.control-arrow::before {
    margin: 6px 5px;
  }
  .workshop-details .full-wrapper {
    padding: 10px;
  }

  .workshop-details .date-section {
    padding: 10px 15px;
    font-size: 12px;
  }
  .workshop-list .workshop-desktop-view-only {
    display: none;
  }

  .workshop-list .workshop-mobile-view-only {
    display: block;
  }

  .workshop-list .workshop-image {
    width: 100%;
  }

  .workshop-list .amount-image-wrapper {
    position: absolute;
    top: 20px;
    left: 12px;
    height: 25px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 13px;
    font-family: "Poppins Medium";
    text-align: center;
    padding-top: 3px;
  }

  .problem-area .professional-near-you-wrapper {
    padding: 10px;
  }

  .problem-area .problem-more-768-pr-15 {
    padding-right: 0;
  }

  .problem-area .problem-more-768-pr-15-for-2 {
    padding-right: 0;
  }

  .problem-area .problem-more-768-pb-15-for-1 {
    padding-bottom: 10px;
  }

  .problem-area .problem-category-heading {
    font-size: 18px;
  }

  .problem-area .common-problem-heading {
    font-size: 18px;
  }

  .problem-area-details h4 {
    font-size: 18px;
  }

  .problem-area .problem-question {
    font-size: 15px;
  }

  .problem-area .problem-answer {
    font-size: 14px;
  }

  .problem-area .problem-category {
    font-size: 13px;
  }

  .problem-area .upcoming-past-button {
    padding: 5px 10px;
  }

  .booking-list .hide-less-575 {
    display: none;
  }

  .booking-list .hide-row-less-575 {
    display: none;
  }

  .booking-list .show-less-575 {
    display: block;
  }

  .booking-list .show-row-less-575 {
    display: flex;
  }

  .booking-list .bor-right {
    border: 0;
  }

  .booking-list .fee-text {
    padding: 0 5px !important;
    font-size: 12px;
  }

  .booking-list .total-seats-text,
  .booking-list .available-seats-text {
    font-size: 14px !important;
    margin-top: 0.5rem;
  }

  .booking-list .time-slot-less-575 {
    padding-top: 5px;
    display: inline-block;
    vertical-align: top;
  }

  .booking-list .event-details-time-date-wrapper {
    width: 50%;
  }

  .booking-list .event-list-wrapper {
    padding-left: 0;
  }

  .booking-list .event-list-item-wrapper .carousel-root {
    height: 200px;
  }

  .forum-wrapper .answer,
  .forum-wrapper .professional-designation-selected,
  .forum-wrapper .professional-designation-not-selected {
    padding: 10px;
  }

  .forum-wrapper .professional-designation {
    font-size: 15px;
  }

  .forum-wrapper .background-image-professional {
    width: 50px;
    height: 50px;
  }

  .forum-wrapper .answer-text {
    padding: 15px;
    width: calc(100% - 70px);
    font-size: 13px;
  }

  .forum-wrapper .message-input {
    width: calc(100% - 70px);
  }

  .forum-wrapper .answer-commentedby {
    font-size: 14px;
  }

  .forum-wrapper .answer-text .answer-date {
    font-size: 12px;
  }

  .forum-wrapper .answer-commentedby {
    font-size: 14px;
  }

  .forum-wrapper .message-input .textarea {
    padding: 15px 45px 15px 15px;
  }

  .forum-wrapper .message-input img {
    width: 30px;
  }

  .forum-wrapper .message-input button {
    right: 5px;
    top: 18px;
  }

  .fav-professional-wrapper .dashboard-less-768-pb-15 {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 430px) {
  .booking-list .professional-date-time .label {
    font-size: 12px;
  }

  .booking-list .professional-date-time {
    font-size: 12px;
  }

  .booking-list .professional-amount {
    font-size: 12px;
  }

  .booking-list .professional-amount .amount {
    padding-top: 5px !important;
  }

  .problem-area .professional-wrapper .booking-item-wrapper {
    padding: 10px;
  }

  .problem-area .upcoming-past-button-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 0 !important;
  }

  .booking-list .categories-heading {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .booking-list .event-details-time-date-wrapper {
    width: 100%;
  }
}