.gallery .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 8px;
    height: 250px;
    margin: 5px;
}

.gallery .arrow-wrapper {
    font-size: 40px;
}

.gallery .arrow-wrapper .left-arrow {
    margin-right: 50px;
    cursor: pointer;
}

.gallery .arrow-wrapper .right-arrow {
    cursor: pointer;
}