.calendar {
    padding: 25px 35px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 5px 5px 15px rgb(125 139 165 / 20%);
    -webkit-box-shadow: 5px 5px 15px rgb(125 139 165 / 20%);
    -moz-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    position: relative;
}

.calendar .calendar-cell .cell-inner {
    border: 1px solid #185BDB;
    border-radius: 5px;
    padding: 5px;
    color: #185BDB;
    font-weight: 600;
    cursor: pointer;
    height: 85px;
    display: inline-table;
    width: 100%;
}

.calendar .disabled-date {
    opacity: 0.7;
}

.calendar .calendar-cell .cell-inner .number {
    display: table-row;
}

.calendar .calendar-cell .cell-inner.selected {
    background-color: #185BDB;
    color: #fff;
}

.calendar .calendar-cell .cell-inner img {
    margin-bottom: 5px;
    width: 30px;
}

.calendar .calendar-cell {
    padding: 0 10px;
    width: 14%;
    display: inline-block;
}

.calendar .next-button {
    height: 45px;
    width: 45px;
    color: #fff;
    background-color: #185BDB;
    border-radius: 50%;
    cursor: pointer;
    font-size: 22px;
    border: none;
    position: absolute;
    right: -20px;
    top: 30%;
}

.calendar .next-button i,
.calendar .prev-button i {
    font-weight: 600;
}

.calendar .prev-button {
    height: 45px;
    background-color: #185BDB;
    color: #fff;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    font-size: 22px;
    border: none;
    position: absolute;
    left: -20px;
    top: 30%;
}

.calendar .prev-button:disabled {
    cursor: default;
    opacity: 0.7;
}

.calendar .show-above-768 {
    display: table-row;
}

.calendar .hide-above-768 {
    display: none !important;
}

@media screen and (max-width: 768px) {
    .calendar .show-above-768 {
        display: none !important;
    }

    .calendar .hide-above-768 {
        display: block !important;
    }

    .calendar .calendar-cell .cell-inner {
        height: 55px;
    }
}

@media screen and (max-width: 620px) {
    .calendar {
        padding: 10px;
    }
    .calendar .next-button,
    .calendar .prev-button {
        width: 30px;
        height: 30px;
        font-size: 14px;
        top: 33%;
    }
    .calendar .next-button {
        right: -13px;
    }
    .calendar .calendar-cell .cell-inner {
        font-size: 10px;
    }
    .calendar .calendar-cell {
        padding: 0 5px;
    }
    .calendar .calendar-cell .cell-inner img {
        width: 25px;
    }
}

@media screen and (max-width: 465px) {
    .calendar .calendar-cell {
        padding: 0 0 0 5px;
    }
    .calendar .next-button {
        right: -18px;
    }
    .calendar .calendar-cell .cell-inner {
        font-size: 9px;
    }
    .calendar .only-date {
        font-size: 11px;
    }
}
