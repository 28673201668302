// 3rd-party Plugins
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

@font-face {
  font-family: "Open Sans Regular";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans Semi Bold";
  src: url("./fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Light";
  src: url("./fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.box-shadow {
  box-shadow: 0px 0px 10px -4px rgba(253, 245, 245, 0.75);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  background-color: #fff;
}

#myAudio {
  width: 100%;
  margin-top: 10px;
}

#myAudio:focus {
  outline: none;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  // overflow-y: hidden;
}

.header-body-wrapper {
  flex: 1 0 auto;
  // overflow-y: scroll;
  // height: calc(100% - 125px);
}
// .spacer {
//   flex: 1;
// }

.footer {
  height: 90px;
  background-color: rgba(236, 242, 255, 0.7);
  clear: both;
  min-height: 60px;
  width:100%;
  margin-top: auto;
  flex-shrink: 0;
}

.footer .nav {
  padding: 10px 5px 10px 20px;
  display: inline-block;
  text-align: center;
}

.footer .nav li {
  display: inline-block;
}

.footer .copyright-text {
  color: #566986;
  font-family: 'Poppins Regular';
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 5px 20px 10px 10px;
}

.footer .nav .nav-link {
  color: #2C3A4E !important;
  font-family: 'Poppins Medium';
}

.btn-primary {
  background-color: #185BDB !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

textarea {
  resize: none;
}

.pl-5 {
  padding-left: 5px !important;
}

h4 {
  font-family: 'Poppins Semi Bold', sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
}
.no-border {
  border: 0 !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.pt-10 {
  padding-top: 10px;
}
.pt-7 {
  padding-top: 7px;
}

.nav-link, .dropdown-item {
  cursor: pointer;
  color: #185BDB !important;
}

button:disabled {
  cursor: default;
}

.p-15 {
  padding: 15px;
}

.input-error {
  color: #fd397a;
  font-size: 12px;
  margin-top: 5px;
}

.error-border {
  border-color: #fd397a !important;
}

.color-black {
  color: #000;
}

.color-white {
  color: #fff !important;
}

.support-mail-image {
  width: 200px;
  height: 15px;
  vertical-align: sub;
}

.pointer-none {
  pointer-events: none;
}

.display-block {
  display: block;
}

.show-less-than-768 {
  display: none;
}

.show-more-than-768 {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.pos-relative {
  position: relative;
}

.pb-40px {
  padding-bottom: 40px;
}

.fa-rupee-sign {
  font-size: 12px;
}

.btn-outline-primary {
  color: #185CD9;
  border: 2px solid #185CD9;
  font-family: 'Poppins Semi Bold';
  font-size: 14px;
  border-radius: 10px;
}

.btn-outline-default {
  color: #1B3467;
  border: 2px solid #ECF2FF;
  font-family: 'Poppins Semi Bold';
  font-size: 14px;
  border-radius: 10px;
}

.btn-outline-default:hover {
  filter: none;
  background-color: #1B3467;
  color: #fff;
}

.btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link.active {
  background-color: transparent;
  color: #185CD9;
}

.btn.btn-link {
  color: #185CD9;
  text-decoration: underline;
  font-size: 14px;
  font-family: 'Poppins Semi Bold';
}

.modal .modal-content {
  background: #FFFFFF;
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.modal-header {
  padding: 25px 25px 10px 25px;
  border-bottom: 0;
}
.btn-danger {
  font-family: 'Poppins Semi Bold';
  font-size: 14px;
  border-radius: 10px;
}

.services-sessions-button:active,
.services-sessions-button:focus,
.upcoming-past-button:active,
.upcoming-past-button:focus,
.small-book-now-button:disabled {
  color: #185BDB !important;
}

.color-red {
  color: red;
  font-family: 'Poppins Semi Bold';
}

.pdf-viewer {
  width: 1000px;
  height: 100vh;
  border: none;
}

@media only screen and (max-width: 1000px) {
  .pdf-viewer {
    width: 95vw;
  }
}

@media only screen and (max-width: 767px) {
  .show-less-than-768 {
    display: block;
  }
  .show-more-than-768 {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .pad-0-below-500 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btn.btn-link {
    font-size: 12px;
  }
  .btn-outline-primary,
  .btn-outline-default {
    font-size: 12px;
  }
  .modal-header {
    padding: 15px 25px;
  }
}
@media only screen and (max-width: 1025px) {
  .footer {
    height: 90px;
  }
  .footer .nav {
    text-align: center;
    padding-bottom: 5px;
  }
  .footer .copyright-text {
    text-align: center;
    padding-top: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .pad-0-below-768 {
    padding: 5px 0 !important;
  }
  .border-top-less-than-768 {
    border-top: 1px solid #ccc;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .border-top-less-than-768 .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .margin-top-less-than-768 {
    margin-top: 10px;
  }
  .navbar-toggler {
    margin-right: 1rem;
  }
  .navbar-brand {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 440px) {
  .footer .nav .nav-link {
    padding: 5px;
  }
}

@media only screen and (max-width: 395px) {
  .footer .nav li {
    width: 50%;
  }
  .footer {
    height: 105px;
  }
}

.custom-navigation-bar .nav-item.active .nav-link {
  color: #185CD9 !important;
  font-family: 'Poppins Semi Bold';
}

.custom-navigation-bar .nav-item.active {
  border-bottom: 3px solid #185CD9;
}

.dropdown-item.active {
  color: white !important;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-12 {
  font-size: 12px;
}

.please-verify-email-text {
  font-size: 12px;
  color: #d77c2b;
}

.verified-email-text {
  font-size: 12px;
  color: #3bb233;
}

.info-icon-class {
  position: absolute;
  right: 70px;
  top: 16px;
}

.left-minus-100 {
  left: -100px;
}

.hw-70 {
  width: 70px;
  height: 70px;
}
.m-6-auto {
  margin: 6px auto;
}

.m-0-auto {
  margin: 0 auto;
}

.login-dropdown-class {
  height: 35px;
  margin-top: 14px;
}

.info-icon-class i {
  font-size: 18px !important;
}

.line-height-5 {
  line-height: 5;
}

.open-info-icon {
  font-size: 18px;
  padding-right: 10px;
}

.bor-top {
  border-top: 1px solid #ccc;
}

.no-border {
  border: 0;
}

.text-strikethrough {
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 400;
}

.no-data-found img {
  width: 150px;
}

.no-data-found .no-data-button {
  font-size: 14px;
  background: #185BDB;
  border: 1px solid #185BDB;
  border-radius: 50px;
  padding: 10px 25px;
  color: #ffffff;
  font-family: 'Poppins Regular';
}

.no-data-found .no-data-header {
  font-size: 14px;
  font-weight: 500;
  color: #1b3467;
  margin-bottom: 10px;
}

.no-data-found .no-data-desc {
  color: #1b3467;
  font-weight: 400;
  margin-bottom: 10px;
}
