//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//


.my-custom-style {
    padding: 1rem 0.5rem;
    color: kt-brand-color();

    .my-custom-element {
        margin-left: 1rem;
    }
}

.violet-icon {
    color: #5d78ff; 
}
  
.ocean-green-icon {
    color: #1dcce0; 
}
  
.bluish-violet-icon {
    color: #4A56CF; 
}
  
.green-icon {
    color: #0ab47e; 
}

.violet-icon {
	color: #5d78ff;
}
.ocean-green-icon {
	color: #1dcce0;
}
.bluish-violet-icon {
	color: #4A56CF;
}
.green-icon {
	color: #0ab47e;
}
.kt-subheader {
	padding: 10px 25px;
}
.kt-aside__brand {
	.kt-aside__brand-logo {
		max-width: 105px;
		margin: auto;
		img {
			max-width: 100%;
		}
	}
}

//global
.kt-header {
	.kt-header__topbar {
		.kt-header__topbar-item {
			.kt-header__topbar-icon {
				margin-right: 10px;
				i.fa-bell {
					color: #717696;
				}
				.notification-count {
					position: absolute;
					top: 0;
					right: 0;
					background: #ccc;
					width: 22px;
					border-radius: 50%;
					height: 22px;
					text-align: center;
					color: #fff;
					background: #7367f0;
					line-height: 22px;
					font-size: 11px;
					font-weight: 500;
				}
			}
		}
	}
} 
.kt-container.kt-container--fluid.custom-subheader {
	h3 {
		font-size: 16px;
		font-weight: 500;
		color: #434349;
		margin-top: 0px;
		margin-bottom: 12px;
	}
	p {
		font-size: 12px;
		line-height: 20px;
		color: #74788d;
		margin-bottom: 49px;
		max-width: 835px;
	}
}
.kt-subheader--enabled:not(.kt-subheader--fixed) .kt-content {
	&.body-content {
		padding-top: 49px;
	}
}
.kt-portlet__head-icon {
	&.custom-styles {
		i {
			font-size: 20px;
			line-height: 26px;
		}
	}
}

.kt-section__title {
	&+.custom-title-base {
		font-size: 14px;
	}
}

.inline-form {
	.row.form-group {
		align-items: center;
		label {
			margin: 0;
			text-align: right;
		}
	}
}

.notification-seen {
	opacity: 0.5;
}

table {
	&.dataTable {
		thead {
			.sorting:before, .sorting:after, .sorting_asc:before, .sorting_asc:after {
				font-size: 18px;
			}
		}
	}
}

.single-form-group {
	margin-bottom: 0;
}

.kt-portlet__head-title {
	.special-note {
		display: inline;
		padding: 0 0 0 10px;
		font-size: 12px;
		font-weight: 300;
		border-left: 1px solid #ccc;
		margin-left: 5px;

		span {
			color: #8e8a8a;
			margin-left: 5px;
			margin-right: 3px;
			i {
				margin-right: 2px;
			}
		}
	}
}

.input-wrap {
	&.input-group {
		&.phone {
			select {
				padding: 8px 6px;
			}
			input {
				flex: 0 0 69%;
			}
		}
	}
}

// Login Base
.kt-login.kt-login--v1 {
    // Aside
    .kt-login__aside {
        // width: 605px;
        width: 45%;
        padding: 3rem 3.5rem;
        // background-image: url(../media/bg/login-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;

        .kt-login__subtitle {
            font-size: 21px;
            line-height: 36px;
            margin: 16px 0 3.5rem 0;
            color: rgb(255, 255, 255);
        }
    }

    .kt-login__wrapper {
    	.kt-login__body {
    		.kt-login__form.kt-login__forgot {
				.kt-login__actions {
		    		justify-content: flex-end;
		    	}
		    }
    	}
    }   

    &.kt-login--signin {
        .kt-login__signup {
            display: none;
        }

        .kt-login__signin {
            display: block;
        }

        .kt-login__forgot {
            display: none;
        }
    }

    &.kt-login--signup {
        .kt-login__signup {
            display: block;
        }

        .kt-login__signin {
            display: none;
        }

        .kt-login__forgot {
            display: none;
        }

        .kt-login__account {
            display: none;
        }
    }

    &.kt-login--forgot {
        .kt-login__signup {
            display: none;
        }

        .kt-login__signin {
            display: none;
        }

        .kt-login__forgot {
            display: block;
        }
    }
}

//Dashboard
.dashboard-activity-panel {
	&.kt-portlet.kt-portlet--height-fluid {
		height: calc(100% - 0px);
		.kt-widget17 {
			.kt-widget17__stats {
				margin: -10rem auto 0 auto;
			}
			.kt-widget17__visual {
				.kt-widget17__chart {
					padding-top: 5rem;
				}
			}
		}
	}
}
.dashboard-revenue-chart {
	flex-wrap: wrap;
	label {
		height: 38px;
		margin: 10px 10px 0 0;
	}
	.bootstrap-select {
		flex: 0 0 50%;
		height: 38px;
	}
}

//Profile
.kt-widget {
	&.kt-widget--user-profile-1 {
		.kt-widget__items.nav {
			display: initial;
			margin: 0;
			border-bottom: 0;
			.kt-widget__item.active {
				-webkit-transition: color 0.3s ease;
				transition: color 0.3s ease;
				background: #f2f3f7;
				border-radius: 4px;
				.kt-widget__section .kt-widget__icon .kt-svg-icon g [fill] {
					fill: #5d78ff;
				}
				.kt-widget__section .kt-widget__desc {
					color: #5d78ff;
				}
			}
		}
	}
}

//Roles
.accessible-modules {
	.modules {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 15px;
		margin-bottom: 15px;
		padding: 0 35px;
		label.kt-checkbox {
			margin: 0;
		}
		.dropdown {
			&.bootstrap-select {
				width: 45%;
			 	.dropdown-toggle {
			 		padding: 5px 1rem;
			 	}
			}
		}
	}
	&.accessible-modules-details {
		.modules {
			justify-content: initial;
			margin-top: 15px;
			margin-bottom: 15px;
			padding: 0 15px;
			.a-m-name {
				margin: 0 10px;
			}
		}
	}
}


/*details*/


.details-section{
	.kt-widget12{
		&.details-text-modifier{
			.kt-widget12__content{
				.kt-widget12__item{
					.kt-widget12__info{
						.kt-widget12__desc{
							color: #a9a8b6;
						}
						.kt-widget12__value{
							max-width: 400px;
							font-size: 1.2rem;
    						font-weight: 400;
							.kt-todo__icon{
								&.kt-todo__icon--on{
									&.kt-todo__icon--light{
										i{
											color: #ffb822!important;
										}
									}
								}
							}
						}
					}
				}
			}
			&.details-text-left-padding{
				padding-left:60px;
			}
		}
	}
	&.details-section-margin-bottom{
		margin-bottom: 20px;
	}
	.details-img{
		img{
			width: 100%;
			display: block;
		}
	}
	.kt-section{
		&.details-kt-section-modifier{
			margin: 0 0 1rem 0;
			.kt-section__body{
				.kt-section__title{
					font-size: 1.40rem;
					margin: 0 0 1.50rem 0;
				}
			}
		}
	}
	.kt-widget-half-wraper{
		display: table;
		width: 100%;
		.kt-widget-half{
			display: table-cell;
			width: 50%;
			vertical-align: top;
			.kt-widget-half-image{
				max-width: 247px;
				img{
					display: block;
					width: 100%;
				}
			}
		}
	}

	
}


/*advanced-search*/
.advanced-search-sec {
	.btn-collapse {
		float: right;
		background: #f5f5f5;
		border: none;
		color: #656565;
		padding: 8px 12px;
		font-size: 12px;
		i {
			font-size: 14px;
			padding: 0;
		}
	}
	.form-control {
		height: 34px;
	}
	select.form-control {
		padding-top: 6px;
	}
	.advanced-search-btn-wraper{
		margin-top: 24px;
		.btn {
		    padding: 7px 14px;
		    &:first-child {
		    	border: none;
		    }
		}

	}
}

/*personal-details*/
.personal-details-modifair{
	.kt-avatar{
		.kt-avatar__holder{
			width: 268px;
    		height: 165px;
		}
	}
	.personal-details-left{
		padding-right:40px;
	}
	.personal-details-right{
		padding-left:40px;
	}
}


/*vehicle image*/ 

.vehicle-image{
	width: 150px;
}


.kt-portlet {
    &.kt-portlet-modifair{
    	background-color: #fbfbfb;
    	border: 1px solid #e2e2e2;
    	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    	margin-bottom: 10px;
    	.kt-portlet__head{
    		min-height: 40px;
    		padding: 0 20px;
    		i{
    			float: right;
    			font-size: 22px;
    			line-height: 40px;
    		}
    		.kt-portlet__head-label{
    			.kt-portlet__head-title{
    				font-weight: 400;
    				color: #6b6b6b;
    				font-size: 1.1rem;

    			}
    		}
    	}
    	&:last-child{
    		margin-bottom: 0px;
    	}
    }
} 

/*Set Priority Modal*/
#set-priority-modal{
	.kt-section{
		.kt-section__title{
			&.kt-section__title-sm{
				font-size: 1.0rem;
				color: #909090;
				line-height: 1.5;
				font-weight: 400;
			}
		}
	}
}

/*Aside-menu*/
.kt-aside-menu{
	.kt-menu__nav{
		&>.kt-menu__item{
			&>.kt-menu__submenu{
				.kt-menu__subnav{
					&>.kt-menu__item{
						&>.kt-menu__link{
							padding-left: 60px;
						}
					}
				}
			}
		}
	}
}

//Zones List
.zones-list-container {
	position: relative;
	.zones-list {
		.kt-switch {
			height: 32px;
		}
		label {
			margin-bottom: 0;
		}
		.btn {
			margin-left: 5px;
		}
		.kt-widget4__info {
			font-size: 14px;
		}

	}
	.overlay {
		display: none;
		position: absolute;
		top:0;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,0.75);
		z-index: 9;
	}
}


.kt-portlet{
	.kt-portlet__body{
		&.low-padding{
			padding: 0 10px;
		}
	}
}


@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}
